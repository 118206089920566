import { useEffect, useState } from 'react'

const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine)

  const handleNetwork = () => { setNetwork(window.navigator.onLine) }

  useEffect(() => {
    window.addEventListener('offline', handleNetwork)
    window.addEventListener('online', handleNetwork)

    return () => {
      window.removeEventListener('offline', handleNetwork)
      window.removeEventListener('online', handleNetwork)
    }
  })

  return { isOnline }
}

export default useNetwork
