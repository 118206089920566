import ReactGA from 'react-ga4'

import { environment } from 'config/environment'


export const GAHandler = (action, params, fb) => {
  ReactGA.event(action, params)

  const sendToMeta = fb && window.fbq

  if (!environment.isProduction) {
    console.log('Analytics', { action, params })

    if (sendToMeta) {
      console.log('Meta', { action, params })
    }
  }

  if (environment.isProduction && sendToMeta) {
    window.fbq('track', action)
  }
}
