import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userApi } from 'api';
import { ReactComponent as AddUserSvg } from 'assets/icons/add-user.svg';
import { ReactComponent as ChevronLeftSvg } from 'assets/icons/chevron-left.svg';
import { useModalNew, useResponsive } from 'hooks';
import { Magnifier } from 'icons';
import { Button, Input, ModalCreatorProfile, Text } from 'ui';
import Creator from './Creator';
var MagnifierStyled = function () { return _jsx(Magnifier, { color: '#a1a1aa' }); };
var FavoriteCreators = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _a = userApi.useGetFollowings(), followeds = _a.data, isLoading = _a.isLoading;
    var isMobile = useResponsive().isMobile;
    var modalCreatorProfile = useModalNew();
    var _b = useState(''), search = _b[0], setSearch = _b[1];
    var _c = useState(), currentFolloweds = _c[0], setCurrentFolloweds = _c[1];
    var _d = useState(null), creatorSelected = _d[0], setCreatorSelected = _d[1];
    var followingsFiltered = currentFolloweds === null || currentFolloweds === void 0 ? void 0 : currentFolloweds.filter(function (creator) { return creator.nickname.toLowerCase().includes(search.toLowerCase()); });
    var showEmptyState = !(currentFolloweds === null || currentFolloweds === void 0 ? void 0 : currentFolloweds.length) && !isLoading;
    var goBack = function () {
        if (window.history.state.idx === 0) {
            navigate('/');
        }
        else {
            navigate(-1);
        }
    };
    useEffect(function () {
        if (currentFolloweds)
            return;
        setCurrentFolloweds(followeds);
    }, [followeds]);
    return (_jsxs("div", { style: { width: '100%', padding: '0 16px' }, children: [modalCreatorProfile.isOpen && creatorSelected && (_jsx(ModalCreatorProfile, { nickname: creatorSelected, close: function () {
                    setCreatorSelected(null);
                    modalCreatorProfile.close();
                } })), _jsxs("div", { style: {
                    width: '100%',
                    maxWidth: 572,
                    margin: isMobile ? '64px auto' : '120px auto',
                }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Button, { variant: 'secondary', onClick: goBack, iconLeft: ChevronLeftSvg, iconSize: 16, size: 'small', style: { minWidth: 32 } }), _jsxs(Text, { size: 20, weight: 600, color: 'headline', style: { marginLeft: 16 }, children: [t('favorites:title'), _jsxs(Text, { as: 'span', size: 20, weight: 600, style: { marginLeft: 5 }, children: ["(", followeds === null || followeds === void 0 ? void 0 : followeds.length, ")"] })] })] }), showEmptyState ? (_jsxs("div", { style: {
                            width: '100%',
                            maxWidth: 343,
                            margin: isMobile ? '140px auto 0' : '100px auto 0',
                        }, children: [_jsx("div", { style: {
                                    width: 72,
                                    height: 72,
                                    borderRadius: '100%',
                                    backgroundColor: '#a1a1aa',
                                    display: 'grid',
                                    placeItems: 'center',
                                    margin: '0 auto',
                                }, children: _jsx(AddUserSvg, { height: 32, width: 32 }) }), _jsx(Text, { size: 20, weight: 600, align: 'center', color: 'headline', style: { marginTop: 24 }, children: t('favorites:empty.title') }), _jsx(Text, { align: 'center', lineHeight: 24, style: { marginTop: 5 }, children: t('favorites:empty.description') })] })) : (_jsxs(_Fragment, { children: [_jsx("div", { style: { marginTop: 22 }, children: _jsx(Input.Text, { size: 'medium', iconLeft: MagnifierStyled, placeholder: t('favorites:placeholder'), onChange: setSearch, value: search }) }), followingsFiltered === null || followingsFiltered === void 0 ? void 0 : followingsFiltered.map(function (creator) { return (_jsx(Creator, { creator: creator, isFollowed: followeds === null || followeds === void 0 ? void 0 : followeds.find(function (c) { return c.uuid === creator.uuid; }), onClick: function () {
                                    setCreatorSelected(creator.nickname);
                                    modalCreatorProfile.open();
                                } }, creator.uuid)); })] }))] })] }));
};
export default FavoriteCreators;
