import { environment, ENVS } from './environment';
var DEV = ENVS.development.name;
var STG = ENVS.staging.name;
var PROD = ENVS.production.name;
var ALL = [DEV, STG, PROD];
var FLAGS_CONFIG = {
    cast: [DEV],
};
var isEnabled = function (flag) {
    var currentEnv = environment.name;
    var isEnabled = FLAGS_CONFIG[flag].includes(currentEnv);
    return isEnabled;
};
export var FLAGS = { isEnabled: isEnabled };
