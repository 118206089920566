var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useSession } from 'hooks';
import { Button, Card, Div, Flixx, Image, LinkLang, Pill, Text } from 'ui';
import { formatDuration } from 'utils/duration';
import { Check, Container, Content, Footer, TitleWrapper, } from './styles';
var Slide = function (props) {
    var onClickCTA = props.onClickCTA, onClick = props.onClick, cover = props.cover, isSelected = props.isSelected, title = props.title, duration = props.duration, body = props.body, isPurchased = props.isPurchased, price = props.price, pill = props.pill, seasons = props.seasons, to = props.to, variant = props.variant;
    var t = useTranslation().t;
    var isLoggedIn = useSession().isLoggedIn;
    var cardProps = to
        ? { mode: 'select', onClick: onClick !== null && onClick !== void 0 ? onClick : (function () { }) }
        : { mode: 'select', onClick: onClick };
    return (_jsx(Container, { style: { containerType: 'inline-size' }, children: _jsx(LinkLang, { to: to, state: { title: title, body: body, cover: cover }, children: _jsx(Card, __assign({}, cardProps, { variant: variant, isSelected: isSelected, style: {
                    padding: 16,
                    borderWidth: 2,
                }, children: _jsxs(Content, { children: [_jsxs("div", { style: {
                                position: 'relative',
                                display: 'grid',
                                gridTemplateColumns: '36% 64%',
                                gap: 12,
                                height: 'fit-content',
                            }, children: [_jsx(Image, { alt: title, title: title, src: cover, styleImg: { width: '100%' } }), _jsx(TitleWrapper, { children: _jsxs("div", { style: { width: '100%' }, children: [_jsx(Text, { as: 'h3', weight: 'semibold', color: 'headline', lineClamp: 2, children: title }), _jsxs("div", { style: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    marginTop: 4,
                                                    flexWrap: 'wrap',
                                                }, children: [pill && (_jsx(Pill, { value: pill })), duration && (_jsx(Text, { color: 'headline', size: 'xs', children: formatDuration(duration) })), seasons && (_jsx(Text, { color: 'headline', size: 'xs', children: seasons }))] })] }) })] }), _jsx("div", { style: { padding: '10px 0', flexGrow: 1 }, children: _jsx(Text, { size: 'xs', lineClamp: 3, children: body }) }), isLoggedIn && (isPurchased || !!price || !!onClickCTA) && (_jsxs(Footer, { children: [_jsxs(Div, { display: 'flex', alignItems: 'center', gap: 6, children: [isPurchased && (_jsxs(_Fragment, { children: [_jsx(Check, {}), _jsx(Text, { size: 'sm', color: 'headline', children: t('purchased') })] })), !isPurchased && !!price && (_jsx(Flixx, { sizeIcon: 27, textSize: 'sm', textWeight: 'semibold', price: price }))] }), !!onClickCTA && (_jsx(Div, { display: 'flex', alignItems: 'end', children: _jsx(Button, { as: 'div', role: 'button', onClick: onClickCTA, type: 'button', borderRadius: 4, children: t('common:watchNow') }) }))] }))] }) })) }) }));
};
export default Slide;
