var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  justify-content: flex-end;\n\n  position: fixed;\n  top: 190px;\n  right: -200px;\n  z-index: 1;\n\n  width: 250px;\n\n  ", "{\n    margin-top: 16px;\n    gap: 8px;\n  }\n\n  ", "{\n    top: 100px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  justify-content: flex-end;\n\n  position: fixed;\n  top: 190px;\n  right: -200px;\n  z-index: 1;\n\n  width: 250px;\n\n  ", "{\n    margin-top: 16px;\n    gap: 8px;\n  }\n\n  ", "{\n    top: 100px;\n  }\n"])), function (props) { return props.theme.old.is.isTabLand; }, function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
});
var templateObject_1;
