import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { contentApi } from 'api';
import { ReactComponent as CheckSvg } from 'assets/icons/check.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';
import { ReactComponent as LockSvg } from 'assets/icons/lock.svg';
import { ReactComponent as ShareSvg } from 'assets/icons/share.svg';
import { useExternalWallet, useResponsive, useSession } from 'hooks';
import { Button, Flixx, Skeleton, Text } from 'ui';
import { GAHandler } from 'utils/GA';
import Author from '../Author';
var Actions = function (props) {
    var uuid = props.uuid, author = props.author, shareText = props.shareText, showPrice = props.showPrice, contentPrice = props.contentPrice, onWatchNow = props.onWatchNow, watchNowDisabled = props.watchNowDisabled, watchWithAd = props.watchWithAd, _a = props.shareUrl, shareUrl = _a === void 0 ? location.href : _a, purchaseMethods = props.purchaseMethods, tokenGates = props.tokenGates, onWatchNowTG = props.onWatchNowTG;
    var t = useTranslation().t;
    var isMobileBig = useResponsive().isMobileBig;
    var isLoggedIn = useSession().isLoggedIn;
    var _b = useExternalWallet(), isConnected = _b.isConnected, connect = _b.connect, sign = _b.sign, signature = _b.signature, address = _b.address, isWaitingSignature = _b.isWaitingSignature;
    var _c = contentApi.useGetPlayWithToken(uuid), getPlayWithToken = _c.mutateAsync, playData = _c.data, isSuccess = _c.isSuccess, isGettingPlayData = _c.isLoading;
    var _d = useState(false), isLinkCopied = _d[0], setIsLinkCopied = _d[1];
    var isSomeTgAvailable = tokenGates === null || tokenGates === void 0 ? void 0 : tokenGates.some(function (tg) { return tg.isAvailable; });
    var copyUrl = function () {
        navigator.clipboard.writeText(shareUrl);
        GAHandler('share');
    };
    useEffect(function () {
        if (!isSuccess)
            return;
        onWatchNowTG(playData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    useEffect(function () {
        if (!signature || !address)
            return;
        getPlayWithToken({ address: address, signature: signature });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signature]);
    if (!author) {
        return (_jsx(Skeleton, { style: { height: '100%', width: '100%' } }));
    }
    return (_jsxs("div", { style: {
            width: '100%',
            // width: 310,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 30,
            maxHeight: 296,
            minWidth: 310,
            margin: isMobileBig ? '0 auto' : 'unset',
            gridArea: 'actions',
        }, children: [_jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid #27272A',
                    borderRadius: 8,
                    padding: '14.5px 12px',
                    gap: 12,
                }, children: [_jsx(Author, { data: author }), _jsx(Button, { iconLeft: isLinkCopied ? CheckSvg : ShareSvg, onClick: copyUrl, variant: 'secondary', animation: 'success', onAnimationStart: function () { setIsLinkCopied(true); }, onAnimationEnd: function () { setIsLinkCopied(false); }, isFull: true, children: isLinkCopied
                            ? t('common:linkCopied')
                            : shareText })] }), !isLoggedIn && (_jsxs(Text, { color: 'headline', style: {
                    backgroundColor: '#7F39FA33',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 6,
                    width: 'fit-content',
                    margin: '0 auto',
                    padding: '5px 8px',
                    borderRadius: 4,
                }, children: [_jsx(InfoSvg, { height: 20, width: 20 }), _jsx(Text, { as: 'span', color: 'inherit', size: 'xs', weight: 'semibold', style: { textTransform: 'uppercase' }, children: t('anteroom:loginToWatch') })] })), isLoggedIn && purchaseMethods === 1 && (_jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 12,
                    padding: isMobileBig ? 'unset' : '0 13px',
                }, children: [watchWithAd && (_jsx(Text, { size: 14, weight: 500, color: '#FBBF24', style: {
                            visibility: showPrice ? 'visible' : 'hidden',
                            whiteSpace: 'nowrap',
                        }, children: t('anteroom:adMsg') })), _jsx("div", { style: {
                            width: '100%',
                            display: 'flex',
                            gap: 16,
                        }, children: _jsx(Button, { size: isMobileBig ? 'medium' : 'large', onClick: onWatchNow, disabled: watchNowDisabled, style: { flexGrow: 1 }, children: _jsx("span", { style: { display: 'flex', alignItems: 'center', gap: 3 }, children: _jsx(Trans, { defaults: showPrice ? t('anteroom:watchFor') : t('common:watchNow'), components: [
                                        _jsx(Flixx, { sizeIcon: 27, textSize: 'sm', textWeight: 'semibold', price: contentPrice }, 'flixx'),
                                    ] }) }) }) })] })), isLoggedIn && purchaseMethods === 2 && (_jsxs("div", { children: [!isConnected && (_jsx(Button, { variant: 'text', onClick: connect, style: { margin: '0 auto' }, children: _jsx(Text, { as: 'span', color: '#FBBF24', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: t('tokenGating:needToConnectWallet') }) })), isConnected && (_jsxs("div", { children: [_jsxs(Text, { size: 'xs', color: 'headline', style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }, children: [_jsx(LockSvg, { color: 'inherit', height: 13, width: 13 }), _jsx(Text, { as: 'span', color: 'inherit', style: { textTransform: 'uppercase' }, children: t('tokenGating:requiredToken') })] }), tokenGates === null || tokenGates === void 0 ? void 0 : tokenGates.map(function (tg) { return (_jsxs("div", { style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                    marginTop: 14,
                                }, children: [_jsx("img", { src: tg.contract.imageUrl, alt: tg.contract.name, title: tg.contract.name, style: {
                                            height: 24,
                                            width: 24,
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            borderRadius: 4,
                                        } }), _jsxs(Text, { size: 'sm', color: 'headline', style: { flexGrow: 1 }, children: [tg.contract.name, tg.requiredBalance > 1 && (_jsxs(Text, { as: 'span', children: [' ', "(x", tg.requiredBalance, ")"] }))] }), tg.isAvailable && (_jsx(CheckSvg, {})), !tg.isAvailable && tg.contract.url && (_jsx("a", { href: tg.contract.url, target: '_blank', rel: 'noreferrer', children: _jsx(Button, { variant: 'flame-inverted', size: 'small', style: { padding: '0 16px' }, children: t('common:showMore') }) }))] }, tg.uuid)); })] })), _jsx(Button, { variant: 'flame', size: 'large', isFull: true, style: { marginTop: 12 }, disabled: !isConnected || !isSomeTgAvailable, onClick: sign, isLoading: isWaitingSignature || isGettingPlayData, children: isWaitingSignature
                            ? t('tokenGating:waitingSignature')
                            : isGettingPlayData
                                ? t('tokenGating:validating')
                                : t('common:watchNow') })] }))] }));
};
export default Actions;
