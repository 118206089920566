import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Anteroom, Pill } from 'ui';
import { getSubtitles } from 'utils/contents';
import { formatDuration } from 'utils/duration';
var Info = function (props) {
    var short = props.short, title = props.title, body = props.body;
    var _a = short || { category: {} }, category = _a.category.name, // category.name
    audioLang = _a.audioLang, releaseDate = _a.releaseDate, duration = _a.duration;
    var t = useTranslation().t;
    var release = new Date(releaseDate).getFullYear();
    var dur = formatDuration(duration);
    var subs = getSubtitles(short).join(', ');
    return (_jsxs(_Fragment, { children: [_jsx(Anteroom.Title, { isLoading: !title, children: title }), _jsxs("div", { style: {
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: 8,
                }, children: [!!category && (_jsx(Pill, { value: t('common:shorts_one') })), _jsxs(Anteroom.Divider.Root, { isLoading: !category, children: [_jsx(Anteroom.Divider.Child, { children: release }), _jsx(Anteroom.Divider.Child, { children: dur }), _jsx(Anteroom.Divider.Child, { children: category })] })] }), _jsx(Anteroom.Body, { isLoading: !body, children: body }), _jsxs(Anteroom.Divider.Root, { isLoading: !audioLang, children: [_jsxs(Anteroom.Divider.Child, { children: [t('audio'), " (", audioLang, ")"] }), subs.length && (_jsxs(Anteroom.Divider.Child, { children: [t('subtitles'), " (", subs, ")"] }))] })] }));
};
export default Info;
