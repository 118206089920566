import { useEffect, useRef, useState } from 'react'

const useCountdown = (minutes = 5) => {
  const TOTAL_TIME = minutes * 60

  const intervalRef = useRef()

  const [timeLeft, setTimeLeft] = useState(TOTAL_TIME)
  const [isRunning, setIsRunning] = useState(false)

  const clear = () => { clearInterval(intervalRef.current) }

  const start = () => { setIsRunning(timeLeft !== 0) }

  const pause = () => {
    setIsRunning(false)
    clear()
  }

  const stop = () => {
    setTimeLeft(0)
    setIsRunning(false)
    clear()
  }

  const reset = () => { setTimeLeft(TOTAL_TIME) }

  const restart = () => {
    setTimeLeft(TOTAL_TIME)
    setIsRunning(true)
  }

  const isEnded = timeLeft === 0

  const minutesLeft = Math.floor(timeLeft / 60)
  let secondsLeft = timeLeft - (minutesLeft * 60)
  if (secondsLeft < 10) secondsLeft = `0${secondsLeft}`

  useEffect(() => {
    if (timeLeft === 0) setIsRunning(false)
  }, [timeLeft])

  useEffect(() => {
    if (!isRunning) return

    intervalRef.current = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return clear
  }, [timeLeft, isRunning])

  return {
    timeLeft: `${minutesLeft}:${secondsLeft}`,
    start,
    pause,
    stop,
    reset,
    restart,
    isEnded,
    isRunning,
  }
}

export default useCountdown
