import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { APPLE_STORE_URL, PLAY_STORE_URL } from 'config/constants'

const useOpenApp = () => {
  const navigate = useNavigate()

  const openApp = () => {
    window.location = 'flixxo://main'
    const now = new Date().valueOf()

    setTimeout(() => {
      /**
       * When the app is opened, this method is not executed
       * inmediatly but when the user come back to the web.
       * If the user stay out of the web for more than 200ms
       * we can interprete that the app was opened, so we don't
       * need to take the user to de store.
       */
      const lapsedTime = new Date().valueOf() - now

      if (lapsedTime > 1550) return

      if (isIOS) window.location = APPLE_STORE_URL
      if (isAndroid) window.location = PLAY_STORE_URL

      navigate('/', { replace: true })
    }, 1500)
  }

  return { openApp }
}

export default useOpenApp
