import { useEffect, useState } from 'react'
import { isChrome } from 'react-device-detect'
import scriptjs from 'scriptjs'

import { FLAGS } from 'config/flags'


const useCast = () => {
  const [session, setSession] = useState()
  const [url, setUrl] = useState()
  const [currentTime, setCurrentTime] = useState()

  const isAvailable = window.chrome && FLAGS.isEnabled('cast')
  const isRunning = session && url

  const stop = () => {
    session.media[0].stop()
    session.leave()
    setSession(null)
    setUrl(null)
  }

  const play = () => {
    session.media[0].play()
  }

  const pause = () => {
    session.media[0].pause()
  }

  const seek = (seconds) => {
    const req = new window.chrome.cast.media.SeekRequest()
    req.currentTime = seconds
    session.media[0].seek(req)
  }

  const volume = (level) => {
    session.setReceiverVolumeLevel(level)
  }

  const sessionListener = (s) => { setSession(s) }

  const receiverListener = (e) => {
    if (e === 'available') {}
  }

  const onInitSuccess = () => {}

  const onInitError = () => {}

  const loadMedia = () => {
    // console.log('4. call loadMedia');

    const mediaInfo = new window.chrome.cast.media.MediaInfo(url)
    mediaInfo.contentType = 'video/mp4'

    const request = new window.chrome.cast.media.LoadRequest(mediaInfo)
    request.autoplay = false
    request.currentTime = currentTime

    session.loadMedia(
      request,
      () => { console.log('Successfully loaded image.') },
      () => { console.log('Failed to load image.') },
    )
  }

  const init = () => {
    // console.log('2. load cast.media');
    const applicationID = window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
    const sessionRequest = new window.chrome.cast.SessionRequest(applicationID)
    const apiConfig = new window.chrome.cast.ApiConfig(sessionRequest, sessionListener, receiverListener)
    window.chrome.cast.initialize(apiConfig, onInitSuccess, onInitError)
  }

  const launch = (url, currentTime) => {
    // console.log('3. launch');

    window.chrome.cast.requestSession(
      (s) => {
        // console.log('4. launch success', s);
        setSession(s)
        setUrl(url)
        setCurrentTime(currentTime)
      },
      () => { console.log('Error connecting to the Chromecast.') },
    )
  }

  useEffect(() => {
    if (session && url && currentTime) loadMedia()
  }, [session, url, currentTime])

  useEffect(() => {
    if (!isChrome) return

    if (window?.chrome?.cast) {
      // iPhone doesnt enter here
      init()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.chrome?.cast])

  useEffect(() => {
    if (!isChrome) return
    // console.log('1. initialization');
    const url = '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
    scriptjs.get(url, () => {})
  }, [])

  const cast = {
    isAvailable,
    isRunning,
    launch,
    stop,
    play,
    pause,
    seek,
    volume,
  }

  return { cast }
}

export default useCast
