import { jsx as _jsx } from "react/jsx-runtime";
import { useResponsive } from 'hooks';
import { Skeleton } from 'ui';
var Cover = function (props) {
    var src = props.src, alt = props.alt;
    var isTabBig = useResponsive().isTabBig;
    return (_jsx("div", { style: {
            display: 'flex',
            flexGrow: 1,
            maxWidth: isTabBig ? 'unset' : 520,
            gridArea: 'cover',
            aspectRatio: 16 / 9,
        }, children: _jsx("div", { style: {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'start',
            }, children: src ? (_jsx("img", { src: src, alt: alt, loading: 'lazy', style: {
                    borderRadius: 8,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                } })) : (_jsx(Skeleton, { style: {
                    height: '100%',
                    width: '100%',
                    borderRadius: 8,
                } })) }) }));
};
export default Cover;
