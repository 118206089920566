import React from 'react'

import useLocation from 'hooks/useLocation'
import { ErrorCard } from 'ui'

import { Container } from './styles'

class ErrorBoundery extends React.Component {
  constructor (props) {
    super(props)
    this.state = { errorOccurred: false }
  }

  static getDerivedStateFromError (error) {
    return { errorOccurred: true }
  }

  componentDidCatch (error, info) {}

  render () {
    if (this.state.errorOccurred) {
      return (
        <Container $isCreators={this.props.isCreators}>
          <ErrorCard isBackHome />
        </Container>
      )
    } else {
      return this.props.children
    }
  }
}

const ErrorBounderyWrapper = ({ children }) => {
  const { isCreators } = useLocation()

  return (
    <ErrorBoundery isCreators={isCreators}>
      {children}
    </ErrorBoundery>
  )
}

export default ErrorBounderyWrapper
