import { useQueryClient } from '@tanstack/react-query';
import { userApi } from 'api';
var useCheckNewRewards = function () {
    var getUnnoticed = userApi.useGetNewRewards().refetch;
    var queryClient = useQueryClient();
    var checkNewRewards = function () {
        getUnnoticed();
        queryClient.invalidateQueries(['rewards']);
    };
    return { checkNewRewards: checkNewRewards };
};
export default useCheckNewRewards;
