import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useLocation from 'hooks/useLocation'
import { ArrowUp, ArrowDown } from 'icons/'
import { ReactComponent as OopsSVG } from 'assets/errors/oops.svg'

import {
  Container,
  Description,
  Title,
  Details,
  Code,
  SeeDetailsBtn,
  ButtonStyled,
} from './styles'


const ErrorCard = (props) => {
  const { t } = useTranslation()

  const {
    icon: Icon = OopsSVG,
    title = t('error:default.title'),
    description = t('error:default.description'),
    isRefresh,
    isBackHome,
    isRadiusOnlyBottom,
    code = 'W1001',
    theme,
    children,
  } = props

  const [showCode, setShowCode] = useState(false)

  const handleShowCode = () => { setShowCode(prev => !prev) }

  const isCreators = theme === 'light'

  return (
    <Container
      $isCreators={isCreators}
      $isRadiusOnlyBottom={isRadiusOnlyBottom}
    >
      <Icon width={180} height={180} />

      <Title $isCreators={isCreators}>
        {title}
      </Title>

      <Description $isCreators={isCreators}>
        {description}
      </Description>

      {isRefresh && (
        <ButtonStyled variant='text' onClick={() => { window.location.reload() }}>
          {t('retry')}
        </ButtonStyled>
      )}

      {isBackHome && (
        <ButtonStyled variant='text' to='/'>
          {t('goToHome')}
        </ButtonStyled>
      )}

      {children}

      { code && (
        <Details>
          <SeeDetailsBtn onClick={handleShowCode} variant='text'>
            {t('showDetail').toUpperCase()}
            {showCode
              ? <ArrowUp width={10} height={5}/>
              : <ArrowDown width={10} height={5}/>
            }
          </SeeDetailsBtn>

          {showCode && (
            <Code $isCreators={isCreators}>
              {code}
            </Code>
          )}
        </Details>
      )}

    </Container>
  )
}

export default ErrorCard
