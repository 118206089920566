import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Suspense, lazy } from 'react';
import { ReactComponent as DownloadSVG } from 'assets/downloadRight.svg';
import { ReactComponent as QuestionsSVG } from 'assets/questionRight.svg';
import { useModalNew, useResponsive } from 'hooks';
import Helper from './Helper';
import { Container } from './styles';
var ModalFullExperience = lazy(function () { return import('ui/ModalFullExperience'); });
var MsgHelpers = function () {
    var t = useTranslation().t;
    var isTabLand = useResponsive().isTabLand;
    var modalFullExp = useModalNew();
    return (_jsxs(_Fragment, { children: [modalFullExp.isOpen && (_jsx(Suspense, { fallback: null, children: _jsx(ModalFullExperience, { close: modalFullExp.close }) })), _jsxs(Container, { children: [_jsx(Helper, { to: 'faqs', title: t('faqs'), icon: QuestionsSVG }), _jsx(Helper, { onClick: modalFullExp.open, title: t('downloadApp'), icon: DownloadSVG })] })] }));
};
export default MsgHelpers;
