import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Anteroom, Pill } from 'ui';
export var SerieInfo = function (props) {
    var title = props.title, body = props.body, tag = props.tag, audioLang = props.audioLang, countSeasons = props.countSeasons, releaseYear = props.releaseYear, subtitles = props.subtitles;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(Anteroom.Title, { isLoading: !title, children: title }), _jsxs("div", { style: {
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: 8,
                }, children: [!!tag && (_jsx(Pill, { value: t('common:microseries', { count: 1 }) })), _jsxs(Anteroom.Divider.Root, { isLoading: !tag, children: [_jsx(Anteroom.Divider.Child, { children: releaseYear }), _jsxs(Anteroom.Divider.Child, { children: [countSeasons, " ", t('common:seasons', { count: countSeasons })] }), _jsx(Anteroom.Divider.Child, { children: tag })] })] }), _jsx(Anteroom.Body, { isLoading: !body, children: body }), _jsxs(Anteroom.Divider.Root, { isLoading: !audioLang, children: [_jsxs(Anteroom.Divider.Child, { children: [t('audio'), " (", audioLang, ")"] }), subtitles.length && (_jsxs(Anteroom.Divider.Child, { children: [t('subtitles'), " (", subtitles.join(', '), ")"] }))] })] }));
};
