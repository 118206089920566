import styled, { css } from 'styled-components'

import { Button } from '..'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  
  & > svg {
    color: ${({ theme }) => theme.old.colors.gray[200]}
  }

  ${({ $isCreators }) => $isCreators && css`
    background-color: ${({ theme }) => theme.old.colors.white};
    width: 80vw;
    max-width: 700px;
    border-radius: 8px;
    padding: 50px 20px 70px;

    & > svg {
      color: ${({ theme }) => theme.old.colors.gray[800]}
    }
  `}
`

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.old.fonts.size.normal};
  font-weight: ${({ theme }) => theme.old.fonts.weight.semibold};
  color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[450]
    : theme.old.colors.white
};
  margin-top: 24px;
  text-align: center;
  max-width: 80vw;
`

export const Description = styled.p`
  font-size: ${({ theme }) => theme.old.fonts.size.normal};
  font-weight: ${({ theme }) => theme.old.fonts.weight.regular};
  color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[600]
    : theme.old.colors.gray[450]
};

  white-space: pre-wrap;
  margin-top: 15px;
  text-align: center;
  max-width: 80vw;
`

export const Details = styled.div`
  font-size: ${({ theme }) => theme.old.fonts.size.normal};
  font-weight: ${({ theme }) => theme.old.fonts.weight.regular};
  color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[600]
    : theme.old.colors.gray[450]
};
  /* margin: 8px 0 32px; */
  margin-top: 15px;
  position: relative;
`

export const ButtonStyled = styled(Button)`
  margin-top: 15px;
`

export const SeeDetailsBtn = styled(Button)`
  display: flex;
  gap: 5px;

  font-size: ${({ theme }) => theme.old.fonts.size.small};
  font-weight: ${({ theme }) => theme.old.fonts.weight.medium};

  &, :hover {
    color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[100]
    : theme.old.colors.gray[600]
};
  }
`

export const Code = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  padding: 10px 12px;
  border-radius: 7px;

  font-size: ${({ theme }) => theme.old.fonts.size.normal};
  font-weight: ${({ theme }) => theme.old.fonts.weight.regular};
  
  color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[600]
    : theme.old.colors.gray[450]
};

  background-color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[800]
    : theme.old.colors.gray[100]
};
`
