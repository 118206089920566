var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useExternalWallet, useResponsive } from 'hooks';
import { Button, Text } from 'ui';
import { shortAddress } from 'utils/wallet';
var ButtonFlame = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: none;\n  position: relative;\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n \n    border-radius: 8px; \n    padding: 2px; \n    background: linear-gradient(118.36deg, #DC02FF 0%, #FFD600 100%);\n\n    mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: xor;\n    mask-composite: exclude;\n  }\n\n  &:hover{\n    &::after {\n      background: white;\n    }\n  } \n"], ["\n  border: none;\n  position: relative;\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n \n    border-radius: 8px; \n    padding: 2px; \n    background: linear-gradient(118.36deg, #DC02FF 0%, #FFD600 100%);\n\n    mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: xor;\n    mask-composite: exclude;\n  }\n\n  &:hover{\n    &::after {\n      background: white;\n    }\n  } \n"])));
var WalletConnection = function () {
    var t = useTranslation().t;
    var _a = useExternalWallet(), isConnected = _a.isConnected, address = _a.address, disconnect = _a.disconnect;
    var _b = useResponsive(), isTab = _b.isTab, isMobile = _b.isMobile, isMobileSmall = _b.isMobileSmall;
    var _c = useState(false), isFocused = _c[0], setIsFocused = _c[1];
    return (_jsxs(ButtonFlame, { onClick: disconnect, onMouseEnter: function () { setIsFocused(true); }, onMouseLeave: function () { setIsFocused(false); }, variant: 'flame-inverted', style: {
            height: 'unset',
            width: '100%',
            maxWidth: 380,
            borderRadius: 12,
            padding: isMobile ? 12 : 16,
            marginTop: isTab ? 32 : 16,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isMobileSmall
                ? 'column'
                : 'row',
            alignItems: isMobileSmall
                ? 'center'
                : 'end',
            gap: isMobileSmall
                ? 16
                : 10,
        }, children: [_jsxs("div", { children: [_jsxs("div", { style: {
                            display: 'flex',
                            gap: 8,
                            alignItems: 'center',
                        }, children: [_jsx(Text, { as: 'span', size: 'l', color: 'headline', weight: 600, style: { whiteSpace: 'nowrap' }, children: isConnected
                                    ? t('tokenGating:walletConnected')
                                    : t('tokenGating:walletDisconnected') }), _jsx("div", { style: {
                                    background: isConnected ? '#059669' : '#F43F5E',
                                    width: 14,
                                    height: 14,
                                    borderRadius: 100,
                                } })] }), _jsx("div", { style: {
                            backgroundColor: '#FFFFFF33',
                            borderRadius: 8,
                            padding: 6,
                            width: '100%',
                            maxWidth: 174,
                            marginTop: 8,
                        }, children: _jsx(Text, { color: 'headline', align: 'center', children: isConnected
                                ? shortAddress(address)
                                : '....' }) })] }), _jsx(Text, { weight: 600, color: isFocused
                    ? 'white'
                    : isConnected ? '#F43F5E' : '#059669', children: isConnected
                    ? t('tokenGating:disconnect')
                    : t('tokenGating:connect') })] }));
};
export default WalletConnection;
var templateObject_1;
