var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { arbitrum, mainnet } from 'wagmi/chains';
import { CAPTCHA_TOKEN } from 'config/constants';
import { environment } from 'config/environment';
import ModalProvider from 'providers/modal';
import SessionProvider from 'providers/session';
import SnackbarProvider from 'providers/snackbar';
import ThemeProvider from 'providers/theme';
import { Snackbar, Toast } from 'ui';
import Settings from 'utils/Settings';
import pkgJson from '../package.json';
import './i18n';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import App from './App';
import { WagmiProvider } from 'wagmi';
var container = document.getElementById('root');
var root = createRoot(container);
var projectId = '92a7d4ac291a30730d0e8d2a71f85f34';
var metadata = {
    name: 'Flixxo',
    description: 'Flixxo',
    url: 'https://www.flixxo.com',
    icons: ['https://www.flixxo.com/favicon.png'],
};
var chains = [mainnet, arbitrum];
var wagmiConfig = defaultWagmiConfig({ chains: chains, projectId: projectId, metadata: metadata });
createWeb3Modal({ wagmiConfig: wagmiConfig, projectId: projectId });
export var Index = function () {
    var queryClient = useState(function () { return new QueryClient({
        defaultOptions: {
            queries: {
                retry: 1,
                retryDelay: 300,
            },
        },
    }); })[0];
    useEffect(function () {
        ReactGA.initialize(environment.GA4_ID);
        if (environment.isProduction) {
            TagManager.initialize({ gtmId: environment.GTM_ID });
            if (window.fbq) {
                window.fbq('init', '339303390613594');
                window.fbq('track', 'PageView');
            }
        }
    }, []);
    useEffect(function () {
        if (location.hostname === 'localhost')
            return;
        import('@sentry/react').then(function (Sentry) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Sentry.init({
                    environment: environment.name,
                    release: pkgJson.version,
                    dsn: 'https://08b7544bfc0b96772dd51bf1c1629cfc@o1253851.ingest.sentry.io/4506701452083200',
                    integrations: [
                        // Sentry.browserTracingIntegration(),
                        Sentry.reactRouterV6BrowserTracingIntegration({
                            useEffect: useEffect,
                            useLocation: useLocation,
                            useNavigationType: useNavigationType,
                            createRoutesFromChildren: createRoutesFromChildren,
                            matchRoutes: matchRoutes,
                        }),
                        Sentry.replayIntegration({
                            maskAllText: false,
                            blockAllMedia: false,
                        }),
                    ],
                    // Performance Monitoring
                    tracesSampleRate: 1.0, //  Capture 100% of the transactions
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: [
                    // 'https://api.dev.flixxo.com/api',
                    // 'https://api.stg.flixxo.com/api',
                    // 'https://api.pro.flixxo.com/api',
                    ],
                    // Session Replay
                    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                });
                return [2 /*return*/];
            });
        }); });
    }, []);
    return (_jsx(GoogleReCaptchaProvider, { reCaptchaKey: CAPTCHA_TOKEN, children: _jsx(BrowserRouter, { children: _jsx(WagmiProvider, { config: wagmiConfig, children: _jsxs(QueryClientProvider, { client: queryClient, children: [_jsx(ReactQueryDevtools, {}), _jsx(SnackbarProvider, { children: _jsx(SessionProvider, { children: _jsx(GoogleOAuthProvider, { clientId: environment.GOOGLE_ID, children: _jsx(ModalProvider, { children: _jsxs(ThemeProvider, { children: [_jsx(Toast.Provider, {}), _jsx(Snackbar, {}), _jsx(App, {}), !environment.isProduction && (_jsx(Settings, {}))] }) }) }) }) })] }) }) }) }));
};
root.render(_jsx(Index, {}));
