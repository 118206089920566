const es = {
  common: {
    value: 'Valor',
    ok: 'Entendido',
    needHelp: '¿Necesitás ayuda?',
    contents: 'Contenidos',
    all: 'Todo',
    microseries_one: 'Microserie',
    microseries_other: 'Microseries',
    shorts_one: 'Corto',
    shorts_other: 'Cortos',
    following: 'Siguiendo',
    follow: 'Seguir',
    cancel: 'Cancelar',
    close: 'Cerrar',
    continue: 'Continuar',
    logout: 'Cerrar sesión',
    save: 'Guardar',
    confirm: 'Confirmar',
    edit: 'Editar',
    seasons_short: 'T',
    seasons_one: 'Temporada',
    seasons_other: 'Temporadas',
    episodes_short: 'E',
    episodes_one: 'Episodio',
    episodes_other: 'Episodios',
    creator: 'Creador',
    watchNow: 'Ver ahora',
    back: 'Atrás',
    finish: 'Finalizar',
    linkCopied: '¡Link copiado!',
    support_creators: 'Apoyar',
    new: 'Nuevo',
    home: 'Inicio',
    conecting: 'Conectando',
    showMore: 'Ver más',
    sec: 'seg',
    status: {
      pending: 'Pendiente',
      confirmed: 'Confirmado',
      cancelled: 'Cancelado',
    },
    gender: {
      male: 'Hombre',
      female: 'Mujer',
      other: 'Otro',
    },
  },
  profile: {
    title: 'Editar perfil',
    description: 'Modificá tu apodo, imagen de perfil y la bio que querés mostrar',
    nickname: 'Tu apodo',
    nicknameValidation: 'Entre 3 y 16 caracteres, con al menos 1 letra',
    bioPlaceholder: 'Compartí algo de vos...',
    optionsTitle: 'Editar foto de perfil',
    optionsChoose: 'Elegir desde dispositivo',
    optionsTake: 'Tomar foto',
    optionsDelete: 'Eliminar foto',
    takeDescription: 'Tomate una foto para colocar de foto de perfil',
    confirmTitle: 'Confirmar foto de perfil',
    confirmDescription: 'Antes, ajustá la imagen como más te guste. Si no estás conforme podes tomar otra.',
    confirmReTake: 'Tomar otra foto',
    toastSuccess: 'Los cambios se guardaron exitosamente',
    toastError: 'No se pudieron guardar los cambios, intente nuevamente más tarde',
    toastSuccessError: 'Algunos cambios no se pudieron guardar, intente nuevamente más tarde',
    toastNickname: 'El apodo que ingresaste ya está registrado.',
  },
  favorites: {
    link: 'Creadores favoritos',
    title: 'Tus creadores favoritos',
    placeholder: 'Buscar...',
    empty: {
      title: 'Elegí tus creadores favoritos',
      description: 'Desde la antesala de un contenido podés ver a su creador y marcarlo como favorito para no perderte nada.',
    },
  },
  userInfo: {
    title: 'Información personal',
    description: 'Modificá tus datos personales que no se visualizan en tu Perfil',
    message: 'Para mejorar tu experiencia en Flixxo, te pedimos que completes estos datos.',
    fullname: 'Nombre y Apellido',
    birthdate: 'Fecha de nacimiento',
    gender: 'Género',
    country: 'País de residencia',
    successMsg: 'Los cambios se guardaron exitosamente',
  },
  challenges: {
    inviteMsg: '¡Te invito a Flixxo! Descubrí las mejores microseries y cortometrajes. Registrate desde el link y ganá tus primeros Flixx: play.flixxo.com/referrer/{{nickname}}',
    title: 'Desafíos',
    description: 'Ganá Flixx completando desafíos',
    availables: 'Disponibles',
    completed: 'Cumplidos',
    invite: 'Invitar',
    claim: 'Reclamar',
    claimed: 'Reclamado',
    newRegisters_zero: 'No hay nuevos registros',
    newRegisters_one: '1 nuevo registro',
    newRegisters_other: '{{count}} nuevos registros',
    watchVideo: 'Ver video',
    emptyMsg: 'Aún no tenés desafíos cumplidos :(',
    toast: {
      button: 'Ver desafíos',
      message: {
        pending: '¡Tenés recompensas para reclamar!',
        new: '¡Cumpliste un nuevo desafío!',
      },
    },
  },
  search: {
    placeholder: 'Buscar...',
    results: 'Tus resultados para: ',
    notFound_title: 'No encontramos resultados :(',
    notFound_opt_1: 'Intentá buscar otra microserie o corto',
    notFound_opt_2: 'Modificá los filtros de tipo de contenido',
    notFound_opt_3: 'Utilizá palabras claves',
    notFound_opt_4: 'Ingresá el nombre de actores o géneros',
    moods: {
      title: 'Buscá contenido por tu estado de ánimo',
      cry: 'Llorar',
      love: 'Enamorarse',
      laugh: 'Reír',
      imagine: 'Imaginar',
      shout: 'Gritar',
      think: 'Pensar',
    },
  },
  content: {
    allContents: 'Todo el contenido',
    onlyMicroseries: 'Sólo microseries',
    onlyShorts: 'Sólo cortos',
  },
  creators: {
    seeViewBy: 'Ver vista por:',
    balance: {
      quote: 'Cotización del Flixx',
      value: 'Valor de tus Flixx',
      withdraw: 'Retirar Flixx',
      withdrawals: 'Estado de retiros',
    },
    modalWithdraw: {
      title: 'Retirar Flixx',
      description: 'Enviá facilmente a tu wallet los Flixx que tengas acumulados',
      step1: {
        connectWallet: 'Conectar wallet',
        walletConnected: 'Wallet conectada',
        disconnect: 'Desconectar',
        howMany: '¿Cuánto Flixx querés retirar?',
        available: 'Disponible',
        withdrawTotal: 'Retirar total',
        minLimit: 'Monto mínimo a retirar 1,000 Flixx',
        maxLimit: 'Monto máximo a retirar 100,000 Flixx',
      },
      step2: {
        summary: 'Resumen del envío',
      },
      step3: {
        title: '¡Retiro de Flixx realizado con éxito!',
      },
      info: {
        amount: 'Flixx a enviar',
        wallet: 'Wallet de destino',
        gas: 'El <strong>GAS</strong> de la transacción corre por cuenta nuestra ;)',
        keepInMind: 'Tené en cuenta lo siguiente:',
        bullet1: 'Dentro de las 72 horas hábiles te recibirás por mail la confirmación de la operación.',
        bullet2: 'Mientras hacemos la transferencia por el monto de Flixx, no lo verás en tu saldo.',
        bullet3: 'El seguimiento del estado de la transacción, lo verás desde <btn>estado de retiros.</btn>',
      },
    },
    modalWithdrawals: {
      title: 'Estado de retiros',
      description: 'Mirá el estado de los retiros de Flixx realizados',
      dateTime: 'FECHA Y HORA',
      details: 'DETALLES DE RETIRO',
      status: 'ESTADO',
      flixxTitle: 'Flixx retirados',
    },
  },
  modalOnboarding: {
    title: '¡Un regalito de bienvenida!',
    description: 'Te hicimos un pequeño video para que le saques el máximo provecho a Flixxo. Además, cuando finalice, obtendrás Flixx.',
    cta: 'Ver video de bienvenida',
  },
  lang: {
    changeMessage: 'Adaptamos el contenido al idioma seleccionado.',
  },
  anteroom: {
    loginToWatch: 'Ingresá a Flixxo para ver el contenido',
    watchFor: 'Ver por <0></0>',
    watchFor_female: 'Mirala por <0></0>',
    watchFor_male: 'Miralo por <0></0>',
    similar: 'Contenidos similares',
    adMsg: 'Se mostrará un anuncio y ganarás Flixx',
    creatorPayment: '<yellow>¡{{creator}}</yellow> recibió tus Flixx!',
    series: {
      share: 'Compartir microserie',
      watchNextEp: 'Ver próximo episodio',
    },
    shorts: {
      share: 'Compartir corto',
    },
    postroll: {
      title: '¡Algo de esto\n te va a gustar!',
    },
  },
  error: {
    default: {
      title: 'Algo salió mal',
      description: 'Hubo un error inesperado. Recargá la página y reintentalo.',
    },
    player: {
      playback: {
        title: 'Error de reproducción',
        description: 'Hubo un error en la reproducción del contenido',
      },
    },
    api: {
      unexpected: 'Hubo un error inesperado. Recargá la página y reintentalo.',
      tooManyRequests: 'Demasiados intentos. Probá ingresar más tarde o comunicate con nosotros.',
      humanVerification: 'No podemos completar tu solicitud. Probá ingresar más tarde o comunicate con nosotros.',
    },
  },
  modals: {
    downloadApp: {
      getTheMostOut: '¡Descubrí cómo aprovechar Flixxo al máximo!',
      fullExperience: {
        title: '¡Descargá la app y viví la experiencia completa!',
        description: 'Con la app podés cumplir desafíos, administrar tus flixx con una wallet y más...',
      },
      wallet: {
        title: '¡Descargá la app para donar flixx a tus creadores favoritos!',
        description: 'Desde la app contás con una wallet para poder transferir flixx a creadores y usuarios',
      },
    },
    welcome: {
      title: 'Te damos la bienvenida a Flixxo',
      title_referrer: '¡Registrate y te regalamos Flixx!',
      description: 'Descubrí las mejores microseries y cortometrajes del mundo, <0>gratis</0>.',
      enterWith: 'Ingresá con',
      alsoYouCan: 'O también podés',
    },
    register: {
      nickname: 'Apodo',
      validation: 'Tu apodo debe tener entre 3 y 16 caracteres, con al menos una letra.',
      terms: 'Al continuar confirmás nuestros <0>términos y condiciones</0>, las <1>políticas de privacidad</1> y que sos mayor de 13 años.',
      haveAccount: '¿Ya tenes cuenta? <0>Iniciá sesión</0>',
      enterEmail: 'Ingresá un e-mail y creá tu apodo',
    },
    creatorProfile: {
      content: 'Contenido del creador',
      notFound: 'No encontramos creadores asociados a este link',
    },
    deleteAccount: {
      title: '¿Querés eliminar tu cuenta?',
      disclaimer: 'Perderás tus Flixx, credenciales y contenidos',
      check: 'Acepto eliminar mi cuenta',
      accept: 'Eliminar Cuenta',
      toastMessage: 'Tu solicitud de eliminación de cuenta ha sido registrada exitosamente',
    },
    referred: {
      flow: 'Referidos',
      title: '¿Te invitaron a Flixxo?',
      description: 'Ingresá el apodo de quién te invitó y <0>ambos ganan Flixx.</0>',
      placeholder: 'Apodo de quién te invitó',
      cta: 'Validar invitación',
      cta_loading: 'Validando invitación...',
      skip: 'Continuar sin invitación',
      error: 'Los datos que ingresaste no corresponden al apodo de un usuario.',
      toastSuccess: 'Apodo de referido validado con éxito',
    },
  },
  moreFlixx: {
    title: '¿Querés más Flixx?',
    ad: '¡Mirá un anuncio y ganá Flixx!',
    invite: 'Invitá a quién quieras y ¡ambos ganan Flixx!',
    inviteToast: '¡Se copió el link correctamente!\nCompartilo con las personas que querés que sean parte de Flixxo',
  },
  ad: {
    message: 'Ganando flixx...',
  },
  adblock: {
    title: 'Tenés activado tu\nbloqueador de anuncios',
    description: 'Los anunciantes nos ayudan a que el contenido sea gratis para vos. Desactivá el bloqueador y recargá la página.',
  },
  footer: {
    aboutUs: 'Acerca de nosotros',
    flixx: '¿Qué es un Flixx?',
    terms: 'Términos y condiciones',
    privacy: 'Políticas de Privacidad',
    business: 'Empresa',
    contactUs: 'Contactate con nosotros',
  },
  flixx: {
    title: 'Flixx es la moneda de Flixxo',
    description: 'Con el Flixx vas a poder ver microseries, cortometrajes y bancar a los creadores. Tomamos prestada tecnología del Bitcoin para desarrollar nuestra propia criptomoneda y asegurarnos una monetización justa y transparente. Los Flixx son tuyos, de los creadores, de toda nuestra comunidad, y tienen valor dentro y fuera de nuestras apps',
    imFlixx: 'Hola! Yo soy Flixx',
    getFlixx_title: 'Varias maneras de conseguir Flixx',
    buy: {
      title: 'Comprá Flixx desde Flixxo',
      description: 'Preparamos para vos ofertas de Flixx para que puedas disfrutar la experiencia Flixxo al máximo',
      example_title: '¿Querés más Flixx?',
      example_description: 'Aprovecha las ofertas que tenemos preparadas para vos.',
      example_cta: 'Comprar',
    },
    ads: {
      title: 'Ganá Flixx mirando anuncios',
      description: 'Podés ganar Flixx mirando anuncios cuando quieras, o previo a la reproducción de un contenido si no tenés Flixx suficientes',
    },
    challenges: {
      title: 'Ganá recompensas cumpliendo desafíos',
      description: 'Cumplí los desafíos que tenemos preparados para vos y ganá jugosas recompensas en Flixx (para acceder a los desafíos <0>descargate la App</0>)',
      example_title: 'El primero de muchos...',
      example_description: 'Como bienvenida, te regalamos Flixx por tu primer contenido visto',
      example_prize: 'Premio',
      example_cta: 'Reclamar recompensa',
    },
    exchanges: {
      title: 'Comprá Flixx en un exchange',
      description: 'Desde <0>Uniswap</0> podés comprar y transferir Flixx a tu billetera',
    },
    faqs: {
      title: 'Más información',
      question_1: '¿Puedo ver contenido sin tener Flixx?',
      answer_1: 'Sí. Al momento de reproducir un contenido, si no tenés Flixx, verás un anuncio. Cada anuncio te paga un monto en Flixx que te permite ver el contenido y bancar al creador, quien recibirá parte de esos Flixx de manera instantánea y transparente.',
      question_2: '¿Cuál es el valor en Flixx que tienen los contenidos?',
      answer_2: 'Actualmente los contenidos tienen un valor a partir de los 0.50 Flixx. Si no tenés Flixx suficiente para verlo, podés comprar Flixx o ver un anuncio que cubre la totalidad del valor del mismo.',
      question_3: '¿Cuál es la cotización del Flixx?',
      answer_3: 'Podés ver la cotización del Flixx en <0>CoinMarketCap</0>',
      question_4: '¿Por qué Flixxo utiliza Flixx?',
      answer_4: 'Utilizar nuestra criptomoneda nos permitió crear un ecosistema transparente e inmediato, donde el intercambio de Flixx va sin intermediarios del anunciante al espectador y del espectador al creador.',
      doubts: '¿Te quedaron dudas? <0>contactate con nosotros</0>',
    },
  },
  faq: {
    description: 'Respuestas a las preguntas más frecuentes.',

    whatIsFlixxo_question: '¿Qué es Flixxo?',
    whatIsFlixxo_answer: 'Flixxo es una plataforma de microseries y cortos de todo el mundo. El modelo de distribución y monetización en Flixxo es diferente a todo lo que existe, ya que permite:<bold>A los espectadores:</bold><enter /><li>Que tengan una alternativa para <high>encontrar nuevas voces y contenidos independientes y originales</high>, es decir, que no dependan del algoritmo.</li><li>Que puedan ver en poco tiempo, buenas historias.</li><bold>A los creadores:</bold><enter /><li>Que puedan <high>monetizar de manera instantánea y transparente.</high></li>',

    whatContent_question: '¿Qué tipo de contenido encuentro en Flixxo?',
    whatContent_answer: 'En Flixxo vas a encontrar diversidad de contenidos de creadores emergentes e independientes que descubrimos en festivales de todo el mundo. Nuestra curaduría está enfocada en las nuevas voces creativas de todos los géneros.<enter />Nuestro contenido está dividido en Cortometrajes y Microseries, ideales para ver en cualquier momento y lugar, debido a su corta duración ;)',

    howAccessContent_question: '¿Cómo accedo a mirar el contenido de Flixxo?',
    howAccessContent_answer: 'Fácil. <high>¡Registrate gratis en la web o en la App (<android>Android</android> o <ios>iOS</ios>), adquirí Flixx y mirá el contenido que más te guste!</high>',

    howGetFlixx_question: '¿Cómo adquiero Flixx?',
    howGetFlixx_answer: 'Podés adquirir fácilmente Flixx <high>comprando directamente desde Flixxo.</high><enter />También podés <high>ganar Flixx</high> mirando anuncios y cumpliendo desafíos.<enter />Por último hay alternativas de compra en <high>exchanges externos</high> como Uniswap.',

    whatAreFlixx_question: '¿Qué son los Flixx?',
    whatAreFlixx_answer: 'Flixx es la criptomoneda de nuestra comunidad. Gracias a que usamos el Flixx es posible que los creadores moneticen de manera directa, transparente y descentralizada cada vez que mirás sus contenidos.<enter /><li>Cada vez que mirás un contenido, el creador recibe tus Flixx.</li><li>Cada vez que mirás un anuncio, vos recibís Flixx.</li><li>Cuando cumplís desafíos, te recompensamos con Flixx.</li><li>Podés realizar transferencia de Flixx a otros usuarios. </li><li>Podés donar Flixx a creadores de contenido.</li><enter /><flixx>Conocé más sobre el Flixx</flixx>',
    old_whatAreFlixxx_answer: '',

    flixxGoToCreator_question: '¿Mis Flixx van directo al creador?',
    flixxGoToCreator_answer: 'Ajá. Cada vez que mirás un contenido, el creador recibe tus Flixx de manera instantánea, transparente... ¡y sin intermediarios!',

    canUploadMyContent_question: 'Soy creador, ¿puedo subir mi contenido a Flixxo?',
    canUploadMyContent_answer: 'Claro. Vení, te contamos todo.',

    flixxoDifference_question: '¿Qué nos diferencia de otras plataformas?',
    flixxoDifference_1: 'Contenidos de corta duración',
    flixxoDifference_2: 'Apoyo transparente y directo al creador de contenido',
    flixxoDifference_3: 'Impulsor de creadores emergentes e independientes al mercado',
    flixxoDifference_4: 'No subestima a nadie, no depende de un algoritmo',
    flixxoDifference_5: 'Ecosistema creativo entre creadores y espectadores',
    flixxoDifference_6: 'Sistema de recompensas',

    producing_question: '¿Flixxo produce?',
    producing_answer: 'Los <high>Flixxo Originals</high> son los contenidos producidos por Flixxo. Desde Flixxo Distribution, apoyamos a las creaciones de más de 30 <high>creadores 3.0</high> de todo el mundo, y las promovemos en mercados internacionales, buscando nuevas plataformas para su distribución.',

    creators3_question: '¿Qué son los Creadores 3.0?',
    creators3_1: 'Creadores 3.0 son los protagonistas de un nuevo paradigma de creatividad colaborativa y descentralizada, propias de la tecnología y cultura de la web 3.0.',
    creators3_2: 'El valor fundamental de la web 3.0 es la descentralización, es decir, que no haya un intermediario poniendo las reglas. Esto le devuelve el poder al creador de contenidos para monetizar de forma directa.',

    doubts: '¿Te quedaron dudas? <mail>contactate con nosotros</mail>',
  },
  beCreator: {
    title: 'Impulsemos tu contenido\n<high>cerca de tu comunidad</high>',
    description: 'Acompañamos a creadores independientes de todo el mundo en la producción y distribución de su contenido.',
    cta: 'Quiero ser creador 3.0',
    yourContent: 'Tu contenido',
    benefits: {
      global: '<high>Microseries y Cortometrajes</high> de cualquier parte del mundo.',
      independent: '<high>Creadores independientes</high> que se animan a desafiar el paradigma.',
      diverse: '<high>Contenidos diversos</high> de distintos temas y géneros.',
      advice: '<high>Te asesoramos</high> para que tu contenido cumpla altos estándares.',
      reach: '<high>Te impulsamos</high> para que alcances otros mercado y plataformas.',
      rules: '<high>Tu contenido, tus reglas.</high> Te acompañamos en tu visión.',
    },
    steps: {
      title: 'En Flixxo te abrimos las puertas',
      description: 'Comprendemos el valor y potencial que hay en los contenidos independientes, es por eso, que lo primero que vas a recibir en Flixxo es un abrazo. De ahí en más nuestra misión es acompañarte.',
      reachUs: {
        title: 'Contactate con nosotros',
        description: 'Presentanos tu contenido',
        example_time: 'Hace {{number}} min.',
        example_creator_chat: 'Hola Flixxo! tengo una microserie y un cortometraje que me gustaría publicar en su plataforma 🤍',
        example_flixxo_chat: 'Hola creador, genial! nos encanta recibir propuestas. Trabajemos juntos para publicar el contenido lo antes posible en Flixxo.',
      },
      publish: {
        title: 'Publicamos tu contenido',
        description: 'Una vez que recibimos el contenido, trabajamos juntos para publicarlo',
        example_content: 'Tu contenido',
      },
      monetize: {
        title: 'Monetizá con cada compra',
        description: 'Cada vez que alguien compra un contenido tuyo, ganás Flixx. Los recibís de manera directa, sin intermediarios',
      },
    },
    protagonist: {
      title: 'Convertite en un creador 3.0',
      description: 'Sé el protagonista de tu contenido y de tu comunidad.',
    },
    dashboard: {
      title: 'Cada vez que un usuario compra tu contenido, ganás Flixx',
      description: 'El Flixx es la criptomoneda de Flixxo. Cada vez que alguien compra tu contenido recibís los Flixx directo a tu cuenta.',
      content_description: 'Seguí de cerca las interacciones de tu contenido de manera desglosada.',
      earnings: 'Tus ganancias',
      earnings_description: 'Analizá la evolución de tus ganancias totales o por períodos específicos.',
    },
    footer: {
      title: '¿Estás listo para convertirte en un creador 3.0?',
      faq: '¿Te quedaron preguntas? Seguro las respondimos en nuestras <0>preguntas frecuentes</0>',
    },
  },
  tokenGating: {
    seo_description: 'Viví la experiencia Token Gating en Flixxo. Conectá tu wallet y desbloqueá el acceso a contenidos únicos con tokens válidos',
    description: '¿Tenés el token? Desbloqueá el acceso a contenidos únicos',
    description2: 'Descubrí el catálogo conectando tu wallet',
    cta: 'Conectar Wallet',
    walletConnected: 'Wallet conectada',
    walletDisconnected: 'Wallet desconectada',
    connect: 'Conectar',
    disconnect: 'Desconectar',
    step1: 'Obtené el token',
    step2: 'Conectá tu wallet',
    step3: 'Mirá contenidos',
    availableWithToken: 'Disponible con token',
    needToConnectWallet: 'Debes conectar tu wallet',
    requiredToken: 'Token necesario',
    waitingSignature: 'Esperando firma...',
    validating: 'Validando...',
    faqs: {
      title: 'Preguntas frecuentes',
      question_1: '¿Qué es Token Gating?',
      answer_1: 'Token Gating es una sección de contenidos exclusivos en Flixxo. Cada película, serie o video de esta sección se habilita únicamente a los usuarios que posean el token necesario. Token gating es una nueva manera de construir vínculos más significativos en la comunidad del cine independiente.',
      question_2: '¿Qué necesito para acceder?',
      answer_2: 'Para acceder a los contenidos exclusivos, necesitas tener el token correspondiente en tu wallet, vincular tu wallet a Flixxo y darle play. Nuestro sistema verifica la posesión del token y eso habilita la reproducción.',
      question_3: '¿Cómo consigo tokens?',
      answer_3: 'Cada contenido exclusivo tiene un token propio que lo habilita. En la antesala del contenido te indicamos el token necesario y dónde adquirirlo.',
      question_4: 'Ya tengo el token... ¿ahora qué hago?',
      answer_4: 'Si ya tenés cuenta en Flixxo, una wallet y el token para acceder al contenido que te interesa, solo resta que conectes la wallet a tu cuenta en Flixxo. Para ingresar al contenido elegido, dale play. Nuestro sistema verificará la posesión del token ¡Y listo!',
      question_5: '¿Puedo acceder a contenidos sin el token',
      answer_5: 'En Flixxo contamos con un catálogo de más de 200 microseries y cortometrajes a los cuales podrás acceder libremente, sin necesidad de contar con un token. Para los contenidos exclusivos que se encuentran en la sección Token gating, únicamente podrán acceder quienes tengan el token requerido.',
      question_6: '¿Cómo conecto mi wallet?',
      answer_6: 'Podés conectar tu wallet en esta misma sección, desde el botón Conectar wallet. Elegí la wallet que querés usar y colocá tus credenciales. Una vez conectada la wallet, verás un indicador de que tu wallet está conectada. Podrás desconectar tu wallet en cualquier momento.',
    },
    error: {
      alreadyUsed: 'La wallet ya se encuentra asociada a otra cuenta',
    },
  },

  old: {
    /**
     * COMMON
     */
    goToHome: 'Ir al inicio',
    spanish: 'Español',
    english: 'Inglés',
    enterEmail: 'Ingresá tu e-mail',
    creators: 'Creadores',
    download: 'Descargar',
    downloadApp: 'Descargá la app',
    back: 'Atrás',
    series: 'SERIES',
    min: 'min',
    faqs: 'Preguntas frecuentes',
    o: 'o',
    accept: 'Aceptar',
    cancel: 'Cancelar',
    language: 'Idioma',
    content: 'Contenido',

    /**
     * MODAL
     */
    modal_enjoyYourContent_title: 'Disfrutá tu contenido',
    modal_enjoyYourContent_content: 'Conocé todas las funcionalidades en nuestra app.',
    modal_spendFlixx_title: 'Gastá tus Flixx',
    modal_spendFlixx_content: 'Conocé todas las funcionalidades en nuestra app.',
    scanCodeQR: 'Escanea el código QR y accede a Flixxo',
    fromYourMobilePhone: 'desde tu teléfono móvil.',
    availableOn: 'Disponible en',
    liveFullExperience: '¡Viví la experiencia completa!',

    /**
     * NEW MESSAGES
     */
    retry: 'Reintentar',
    openApp: 'ABRIR EN LA APLICACIÓN',
    backToPlay: 'Volver a Play Flixxo',
    goToCreators: 'Ir a Flixxo Creators',
    showDetail: 'Ver detalle',
    sendCode: 'Obtener código',
    enterPhone: 'Ingresa tu numero de telefono',
    enterCode: 'Ingresa aqui el código',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmá tu contraseña',
    didNotGetCode: '¿No te llegó el código?',
    sendAgain: 'Volver a enviar',
    enter: 'Ingresar',
    purchased: 'Comprado',
    watchFor: 'Ver por',
    watchSE: 'Ver T{{season}} E{{episode}}',
    season: 'Temporada',
    seasons: 'Temporadas',
    subtitles: 'Subtítulos',
    audio: 'Audio',
    video: 'Video',
    videos: 'Videos',
    disabledPlural: 'Desactivados',
    nextEpisode: 'Próximo episodio',
    starting: 'Comenzando',
    continueWatching: 'Continuar viendo',
    didYouEnterWrongNumber: '¿Ingresaste un número incorrecto?',
    selectOption: 'Seleccionar una opción',
    next: 'Siguiente',
    enjoyFullExperience: 'Disfruta de la experiencia completa en nuestra app',
    open: 'Abrir',
    continueOnBrowser: 'Continuar en el navegador',

    statistics: 'Estadísticas',
    newFollowers: 'Nuevos Seguidores',
    status: 'Estado',
    releaseDate: 'Estreno',
    purchases: 'Compras',
    earnings: 'Ganancias',
    price: 'Precio',

    /**
     * MODALS WELCOME, LOGIN, REGISTER, RECOVERY
     */
    login: 'Iniciar sesión',
    loggingIn: 'Iniciando sesión...',
    createAccount: 'Crear cuenta',
    creatingAccount: 'Creando cuenta...',
    goodToSeeYouAgain: '¡Que bueno verte otra vez!👋',
    fillInYourDetails: 'Completá tus datos para ingresar',
    nicknameOrEmail: 'Apodo o e-mail',
    password: 'Contraseña',
    forgotMyPassword: 'Olvidé mi contraseña',
    firstTimeInFlixxo: '¿Primera vez en Flixxo?',
    createYourAccount: 'Creá tu cuenta',
    recoverPassword: 'Recuperar contraseña',
    recoveringPassword: 'Recuperando contraseña...',
    enterYourMobileNumber: 'Ingresá tu número de celular',
    mobileNumber: 'Número de celular',
    mobileInfo: 'Te solicitamos tu número de teléfono para contar con un segundo factor de seguridad al recuperar una contraseña.',
    passwordsDoesntMatch: 'Las contraseñas que ingresaste no coinciden.',
    enterCodeAndCreateNewPwd: 'Ingresá el código que te enviamos y creá la nueva contraseña',
    resendCode: 'Reenviarme el código',
    repeatPassword: 'Repetir contraseña',
    passwordReqs: 'Debe contener al menos una minúscula, una mayúscula y un número. Mínimo 8 caracteres, máximo 30.',
    passwordRecoverySuccessfull: '¡Recuperaste tu contraseña exitosamente!',
    nowYouCanLoginWithNewPwd: 'Ya podés iniciar sesión con la nueva contraseña',
    great: 'Genial',
    email: 'E-mail',

    createPassword: 'Creá una contraseña',
    mustContainOneLowerCase: 'Debe contener al menos una minúscula',
    mustContainOneUpperCase: 'Debe contener al menos una mayúscula',
    mustContainOneNumber: 'Debe contener al menos un número',
    lengthMinMax: 'Mínimo {{min}} caracteres, máximo {{max}}',
    congratsHaveCreatedAnAccount: '¡Felicitaciones! \n Creaste tu cuenta en Flixxo',
    nowYouAreFlixxer: '¡De ahora en más sos Flixxer! 🤘',
    validateAccount: 'Validar cuenta',
    validatingAccount: 'Validando cuenta...',
    finishAccountValidation: 'Finalizá la validación de tu cuenta ingresando tu número de celular',
    enterCodeSentTo: 'Ingresá el código enviado al:\n{{mobileNumber}}',

    recaptcha: 'Este sitio está protegido por reCAPTCHA y se aplican las <0>Políticas de privacidad</0> y los <1>Términos de servicio</1> de Google.',


    /**
     * SEO
     */
    seoSeriesTitle: 'Series',
    seoSeriesDescription: 'Microseries originales del mundo',
    seoSearchTitle: 'Búsqueda',
    seoSearchDescription: 'Buscá los mejores contenidos y videos cortos en Flixxo',
    seoDefaultTitle: 'Flixxo',
    seoDefaultDescription: 'Microseries y videos cortos al estilo de la web3',
    seoBeCreatorTitle: 'Convertite en creador',
    seoBeCreatorDescription: 'Acompañamos a creadores independientes de todo el mundo en la producción y distribución de su microserie.',
    seoFlixxTitle: 'Flixx, nuestra criptomoneda',
    seoFlixxDescription: 'Flixx es la criptomoneda de Flixxo, con la que vas a poder ver microseries grátis y bancar a sus creadores.',

    /**
     * CREATORS
     */
    fromBeginning: 'Desde el inicio',
    last30Days: 'Últimos 30 días',
    last7Days: 'Últimos 7 días',
    fromBeginningPhrase: 'Desde que subiste tu primer contenido',
    periodPhrase: 'Desde el {{ start }} hasta el {{ end }}',
    allContent: 'Todo el contenido',

    noSearchResults: 'No hay resultados para tu búsqueda',
    noSearchResultsDescription: 'Intenta escribiendo de otra manera o busca otro de tus contenidos',
    noContentYet: 'Todavía no tenés ningún contenido',
    noContentYetDescription: 'Comenzá a subir contenido y empieza a crear todo tu público',
    noInternet: 'No tienes conexión a internet',
    noInternetDescription: 'Intenta revisar tu conexión y luego prueba recargando la página',
    serverError: 'Hubo un problema con el servidor',
    serverErrorDescription: 'Prueba recargando la página',

    searchYourVideo: 'Buscá tu video...',
    viewOnFlixxo: 'Ver en Flixxo',
    published: 'Publicado',
    deleted: 'Eliminado',
    paused: 'Pausado',
    unknown: 'Desconocido',

    seasonEpisode: 'T{{season}}E{{episode}}',
    earningsTooltip: 'De tus ingresos (compras), una parte se utiliza para apoyar a la Comunidad.',
    yourContentIsPaused: 'Tu contenido está pausado',
    knowMoreAboutContent: 'Si queres saber más acerca de este contenido escribinos a content@flixxo.com',
    sendUsYourQuestion: 'Escribinos tu consulta',

    /**
     * ERROR MESSAGES
     */
    contentNotFound: 'El contenido que estás buscando no existe',
    wrongCredentials: 'Apodo, e-mail o contraseña incorrecta.',
    emailRegistered: 'El e-mail que ingresaste ya está registrado.',
    emailInvalid: 'El e-mail que ingresaste no posee un formato válido.',
    nicknameRegistered: 'El apodo que ingresaste ya está registrado.',
    passwordsDontMatch: 'Las contraseñas que ingresaste no coinciden',
    mobileNumberInvalid: 'El número que ingresaste no es válido',
    mobileNumberRegistered: 'El número que ingresaste ya está registrado',
    mobileNumberUnknown: 'El número ingresado no pertenece a ningún usuario registrado',
    codeInvalid: 'El código que ingresaste no es válido',
    operationLimitExceeded: 'Superaste la cantidad de intentos permitidos. Por favor intentá de nuevo más tarde.',
    notPossibleLogInThisAccount: 'No es posible iniciar sesión con esta cuenta.',


    /**
     * CONTENT PLAYER
     */
    seasonEpisodeHyphen: 'T{{season}}-E{{episode}}',
  },
}

export default es
