import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
var useGetContent = function (props) {
    var uuid = props.uuid, contentType = props.contentType;
    var apiFetch = useApi().apiFetch;
    var url = "/".concat(contentType, "/").concat(uuid);
    var queryFn = function () {
        if (!uuid)
            return null;
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery({
        queryKey: ['content', contentType, uuid, 'lang'],
        queryFn: queryFn,
        staleTime: 60000 * 30, // 30 min
    });
};
export default useGetContent;
