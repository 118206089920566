import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
var Seo = function (props) {
    var _a = props.title, title = _a === void 0 ? '' : _a, _b = props.description, description = _b === void 0 ? '' : _b, _c = props.canonicalPath, canonicalPath = _c === void 0 ? '' : _c;
    var t = useTranslation().t;
    var getSeoTitle = function (newTitle) {
        if (!newTitle)
            return t('seoDefaultTitle');
        var isTooLong = newTitle.length > 55;
        var seoTitle = isTooLong
            ? "".concat(newTitle.slice(0, 52), "...")
            : newTitle;
        return "".concat(seoTitle, " | Flixxo");
    };
    var getSeoDescription = function (newDescription) {
        if (!newDescription)
            return t('seoDefaultDescription');
        var isTooLong = newDescription.length > 117;
        var seoDescription = isTooLong
            ? "".concat(newDescription.slice(0, 114), "...")
            : newDescription;
        return seoDescription;
    };
    var seoTitle = getSeoTitle(title);
    var seoDescription = getSeoDescription(description);
    var canonical = 'https://play.flixxo.com' + (canonicalPath || location.pathname);
    return (_jsxs(Helmet, { children: [_jsx("title", { children: seoTitle }), _jsx("meta", { name: 'title', content: seoTitle }), _jsx("meta", { name: 'description', content: seoDescription }), _jsx("link", { rel: 'canonical', href: canonical })] }));
};
export default Seo;
