var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  backdrop-filter: ", ";\n  max-width: 100%;\n  z-index: 1;\n  border-radius: ", " 0 0 ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  transition: all 300ms ease;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  height: 50px;\n\n  transform: ", ";\n"], ["\n  background-color: ", ";\n  backdrop-filter: ", ";\n  max-width: 100%;\n  z-index: 1;\n  border-radius: ", " 0 0 ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  transition: all 300ms ease;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  height: 50px;\n\n  transform: ", ";\n"])), function (props) { return props.theme.old.effects.gray; }, function (props) { return props.theme.old.effects.blur; }, function (props) { return props.block ? props.theme.old.rounded.xl : props.theme.old.rounded.xl; }, function (props) { return props.theme.old.rounded.xl; }, function (props) { return props.theme.old.colors.white; }, function (props) { return props.theme.old.fontSize.header; }, function (props) { return props.theme.old.fonts.weight.semibold; }, function (_a) {
    var $isOpen = _a.$isOpen;
    return $isOpen
        ? 'translateX(-200px)'
        : 'translateX(0px)';
});
export var IconWrapper = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 8px;\n  display: grid;\n  place-items: center;\n  border-top-left-radius: 50%;\n  border-bottom-left-radius: 50%;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n"], ["\n  padding: 8px;\n  display: grid;\n  place-items: center;\n  border-top-left-radius: 50%;\n  border-bottom-left-radius: 50%;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n"])));
export var Title = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  display: grid;\n  place-items: center start;\n  padding-left: 12px;\n"], ["\n  height: 100%;\n  width: 100%;\n  display: grid;\n  place-items: center start;\n  padding-left: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
