import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton, Text } from 'ui';
var Body = function (_a) {
    var children = _a.children, isLoading = _a.isLoading;
    if (isLoading) {
        return (_jsx("div", { style: { marginTop: 12 }, children: _jsx(Skeleton, { style: {
                    height: 100,
                    borderRadius: 4,
                } }) }));
    }
    var list = children.split('**');
    var startsBold = children.startsWith('**');
    var text = list.map(function (t, idx) { return ({
        isBold: (idx === 0 && startsBold) || idx % 2 !== 0,
        t: t,
    }); });
    return (_jsx("div", { style: { marginTop: 12 }, children: _jsx(Text, { size: 'sm', weight: 'medium', children: text.map(function (item) { return (_jsx(_Fragment, { children: item.isBold
                    ? _jsx(Text, { as: 'span', weight: 600, color: '#D4D4D8', children: item.t })
                    : item.t })); }) }) }));
};
export default Body;
