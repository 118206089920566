const en = {
  common: {
    value: 'Value',
    ok: 'Ok',
    needHelp: 'Need help?',
    contents: 'Contents',
    all: 'All',
    microseries: 'Microseries',
    shorts_one: 'Short Film',
    shorts_other: 'Short Films',
    following: 'Following',
    follow: 'Follow',
    cancel: 'Cancel',
    close: 'Close',
    continue: 'Continue',
    logout: 'Log out',
    save: 'Save',
    confirm: 'Confirm',
    edit: 'Edit',
    seasons_short: 'S',
    seasons_one: 'Season',
    seasons_other: 'Seasons',
    episodes_short: 'E',
    episodes_one: 'Episode',
    episodes_other: 'Episodes',
    creator: 'Creator',
    watchNow: 'Watch now',
    back: 'Back',
    finish: 'Finish',
    linkCopied: 'Link copied!',
    support_creators: 'Support',
    new: 'New',
    home: 'Home',
    conecting: 'Connecting',
    showMore: 'Show more',
    sec: 'sec',
    status: {
      pending: 'Pending',
      confirmed: 'Confirmed',
      cancelled: 'Cancelled',
    },
    gender: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
  },
  profile: {
    title: 'Edit profile',
    description: 'Edit your nickname, profile image and your bio',
    nickname: 'Your nickname',
    nicknameValidation: 'Should be 3 to 16 characters long with at least 1 letter.',
    bioPlaceholder: 'Share something about yourself...',
    optionsTitle: 'Edit profile picture',
    optionsChoose: 'Choose from device',
    optionsTake: 'Take a picture',
    optionsDelete: 'Delete a picture',
    takeDescription: 'Snap a picture for your profile photo',
    confirmTitle: 'Confirm profile picture',
    confirmDescription: 'First, make any necessary edits to the image according to your preferences. If you\'re not content, feel free to take another one.',
    confirmReTake: 'Take another picture',
    toastSuccess: 'The changes were saved successfully',
    toastError: 'Changes couldn\'t be saved, please try again later',
    toastSuccessError: 'Some changes couldn\'t be saved, please try again later',
    toastNickname: 'The nickname you entered is already taken.',
  },
  favorites: {
    link: 'Favorite creators',
    title: 'Your favorite Creators',
    placeholder: 'Search...',
    empty: {
      title: 'Pick your favorite creators',
      description: 'From the preview of a content, you can see its creator and mark them as a favorite to not miss anything.',
    },
  },
  userInfo: {
    title: 'Personal information',
    description: 'Your personal data is not displayed on your Profile',
    message: 'To improve your experience on Flixxo, please complete these details.',
    fullname: 'Full Name',
    birthdate: 'Birthdate',
    gender: 'Gender',
    country: 'Country of residence',
    successMsg: ' Changes were saved successfully',
  },
  challenges: {
    inviteMsg: 'Come check out Flixxo! Discover the best micro-series and short films. Register using the link and earn your first Flixx: play.flixxo.com/referrer/{{nickname}}',
    title: 'Challenges',
    description: 'Earn Flixx by completing challenges',
    availables: 'Available',
    completed: 'Completed',
    invite: 'Invite',
    claim: 'Claim',
    claimed: 'Claimed',
    newRegisters_zero: 'No new registers',
    newRegisters_one: '1 new register',
    newRegisters_other: '{{count}} new registers',
    watchVideo: 'Watch video',
    emptyMsg: 'You still haven\'t ticked off any challenges yet :(',
    toast: {
      button: 'View challenges',
      message: {
        pending: 'You have pending rewards to claim!',
        new: 'You have completed a new challenge!',
      },
    },
  },
  search: {
    placeholder: 'Search in Flixxo',
    results: 'Your results for: ',
    notFound_title: 'No results found :(',
    notFound_opt_1: 'Try looking for another microseries or short Film',
    notFound_opt_2: 'Modify the content type filters',
    notFound_opt_3: 'Use keywords',
    notFound_opt_4: 'Enter the name of actors or genres',
    moods: {
      title: 'Search content based on your mood',
      cry: 'Cry',
      love: 'Fall in love',
      laugh: 'Laugh',
      imagine: 'Imagine',
      shout: 'Shout',
      think: 'Think',
    },
  },
  content: {
    allContents: 'All the contents',
    onlyMicroseries: 'Only microseries',
    onlyShorts: 'Only short films',
  },
  creators: {
    seeViewBy: 'See view by:',
    balance: {
      quote: 'Flixx quote',
      value: 'Value of your Flixx',
      withdraw: 'Withdraw Flixx',
      withdrawals: 'Withdrawals status',
    },
    modalWithdraw: {
      title: 'Withdraw Flixx',
      description: 'Easily send the Flixx you have accumulated to your wallet',
      step1: {
        connectWallet: 'Connect wallet',
        walletConnected: 'Wallet connected',
        disconnect: 'Disconnect',
        available: 'Available',
        withdrawTotal: 'Withdraw total',
        howMany: 'How many Flixx do you want to withdraw?',
        minLimit: 'Minimum amount to withdraw 1,000 Flixx',
        maxLimit: 'Maximum amount to withdraw 100,000 Flixx',
      },
      step2: {
        summary: 'Summary',
      },
      step3: {
        title: 'Flixx withdrawal successfully completed!',
      },
      info: {
        amount: 'Amount on Flixx',
        wallet: 'Destination wallet',
        gas: 'The <strong>GAS</strong> of the transaction is on us ;)',
        keepInMind: 'Keep the following in mind:',
        bullet1: 'Within 72 business hours you will receive confirmation of the operation by email.',
        bullet2: 'While we transfer the Flixx amount, you will not see it in your balance.',
        bullet3: 'You will see the tracking of the transaction status from the <btn>withdrawal status.</btn>',
      },
    },
    modalWithdrawals: {
      title: 'Withdrawal status',
      description: 'See the status of the Flixx withdrawals made',
      dateTime: 'DATE AND TIME',
      details: 'WITHDRAWAL DETAILS',
      status: 'STATUS',
      flixxTitle: 'Flixx withdrawn',
    },
  },
  modalOnboarding: {
    title: 'A little welcome gift!',
    description: 'We made a short video for you to get the most out of Flixxo. Plus, when it\'s done, you\'ll earn Flixx.',
    cta: 'Watch the welcome video',
  },
  lang: {
    changeMessage: 'We adapt the content to the selected language.',
  },
  anteroom: {
    loginToWatch: 'Log in to Flixxo to watch the content',
    watchFor: 'Watch for <0></0>',
    similar: 'Similar contents',
    adMsg: 'An ad will pop up, and you\'ll earn Flixx',
    creatorPayment: '<yellow>{{creator}}</yellow> got your Flixx!',
    series: {
      share: 'Share microseries',
      watchNextEp: 'Watch next episode',
    },
    shorts: {
      share: 'Share short',
    },
    postroll: {
      title: 'Some of this\n you\'re gonna like!',
    },
  },
  error: {
    default: {
      title: 'Unexpected Error',
      description: 'Reload the page and try again.',
    },
    player: {
      playback: {
        title: 'Playback error',
        description: 'An error has occurred in the content playback',
      },
    },
    api: {
      unexpected: 'Unexpected error. Reload the page and try again.',
      tooManyRequests: 'Too many tries. Try again later or get in touch with us.',
      humanVerification: 'We can\'t fulfill your request. Try again later or get in touch with us.',
    },
  },
  modals: {
    downloadApp: {
      getTheMostOut: 'Discover how the get the most out of Flixxo!',
      fullExperience: {
        title: 'Download the app and enjoy!',
        description: 'The app will let you complete challenges, manage your flixx with a wallet and more...',
      },
      wallet: {
        title: 'Download the app and donate flixx to your favourite creators!',
        description: 'In the app you will have a wallet which you can use to transfer flixx to creators and users',
      },
    },
    welcome: {
      title: 'Welcome to Flixxo!',
      title_referrer: 'Register now and get free Flixx!',
      description: 'Discover the best microseries and short films from around the world, <0>for free</0>.',
      enterWith: 'Enter with',
      alsoYouCan: 'Or also you can',
    },
    register: {
      nickname: 'Nickname',
      validation: 'Nickname must be 3 to 16 characters long and contain at least 1 letter.',
      terms: 'By clicking the "Next" button below you are accepting our <0>terms and conditions</0>, our <1>privacy policies</1> and that you are above 13 years old.',
      haveAccount: 'Already have an account? <0>Log in<0/>',
      enterEmail: 'Enter an e-mail and create your nickname',
    },
    creatorProfile: {
      content: 'Creator\'s content',
      notFound: 'We couldn\'t find any creators associated with this link',
    },
    deleteAccount: {
      title: 'Do you want to delete your account?',
      disclaimer: 'You will lose your Flixx, credentials and content',
      check: 'I agree to delete my account',
      accept: 'Delete account',
      toastMessage: 'Your account deletion request has been successfully registered',
    },
    referred: {
      flow: 'Referral',
      title: 'Were you invited to Flixxo?',
      description: 'Enter the nickname of the person who invited you, and <0>both of you will earn Flixx.</0>',
      placeholder: 'Nickname of who invited you',
      cta: 'Validate invitation',
      cta_loading: 'Validating invitation...',
      skip: 'Continue without an invitation',
      error: 'The data you entered does not match a user\'s nickname.',
      toastSuccess: 'Referral nickname successfully validated',
    },
  },
  moreFlixx: {
    title: 'Do you want more Flixx?',
    ad: 'Watch an ad and earn Flixx!',
    invite: 'Invite anyone you want and both get Flixx!',
    inviteToast: 'You\'ve copied the link correctly!\nShare it with the people you want to be part of Flixxo',
  },
  ad: {
    message: 'Earning flixx...',
  },
  adblock: {
    title: 'Hey, you got your ad blocker on?',
    description: ' Advertisers help keep the content free for you. Turn off the blocker and refresh the page.',
  },
  footer: {
    aboutUs: 'About us',
    flixx: 'What is a Flixx?',
    terms: 'Terms and conditions',
    privacy: 'Privacy policies',
    business: 'Business',
    contactUs: 'Contact us',
  },
  flixx: {
    title: 'Flixx is Flixxo\'s currency',
    description: 'With Flixx, you can watch microseries, short films, and support creators. We borrowed technology from Bitcoin to develop our own cryptocurrency, ensuring fair and transparent monetization. Flixx belongs to you, the creators, our entire community, and holds value both inside and outside our apps',
    imFlixx: 'Hi! I am Flixx',
    getFlixx_title: 'Many ways to snag Flixx',
    buy: {
      title: 'Buy Flixx from Flixxo',
      description: 'Crafted Flixx offers just for you, so you can savor the ultimate Flixxo experience',
      example_title: 'Want more Flixx?',
      example_description: 'Take advantage of the offers we\'ve prepared for you',
      example_cta: 'Buy',
    },
    ads: {
      title: 'Earn Flixx watching ads',
      description: 'You can earn Flixx by watching ads whenever you like, or before playing content if you don\'t have enough Flixx',
    },
    challenges: {
      title: 'Earn rewards by completing challenges',
      description: 'Complete the challenges we have prepared for you and earn generous rewards in Flixx (access the challenges <0>downloading our mobile app</0>)',
      example_title: 'The first of many to come...',
      example_description: 'As a welcome gift, Flixx for your first view',
      example_prize: 'Prize',
      example_cta: 'Claim reward',
    },
    exchanges: {
      title: 'Buy Flixx on an exchange',
      description: 'You can purchase and transfer Flixx to your wallet from <0>Uniswap</0>',
    },
    faqs: {
      title: 'More information',
      question_1: 'Can I watch content without having Flixx?',
      answer_1: 'Yes, when you play content without having Flixx, you\'ll see an ad. Each ad pays you an amount in Flixx that allows you to watch the content and support the creator, who receives a portion of those Flixx instantly and transparently.',
      question_2: 'What\'s the value in Flixx of the content?',
      answer_2: 'Currently, the content is priced starting at 0.50 Flixx. If you don\'t have enough Flixx to watch it, you can either purchase Flixx or watch an ad that covers the full value of the content.',
      question_3: 'What is the price of Flixx?',
      answer_3: 'You can check Flixx price at <0>CoinMarketCap.</0>',
      question_4: 'Why does Flixxo use Flixx?',
      answer_4: 'By using our own cryptocurrency we were able to create an ecosystem where advertisers, audience and content creators can interact and pay each other in an straight and transparent way, without any intermediaries.',
      doubts: 'Any doubts? <0>contact us</0>',
    },
  },
  faq: {
    description: 'Answers to the most frequently asked questions.',

    whatIsFlixxo_question: 'What is Flixxo?',
    whatIsFlixxo_answer: 'Flixxo is a platform for microseries and short films from around the world. The distribution and monetization model on Flixxo is unlike anything else out there, as it allows:<bold>For viewers:</bold><li>To have an alternative to <high>discover new voices and independent, original content</high>, meaning content that doesn\'t rely on algorithms.</li><enter /><li>To watch good stories in a short amount of time.</li><bold>For creators:</bold><li>To <high>monetize instantly and transparently.</high></li>',

    whatContent_question: 'What kind of content can I find on Flixxo?',
    whatContent_answer: 'On Flixxo, you\'ll find a variety of content from emerging and independent creators that we\'ve discovered at festivals worldwide. Our curation focuses on new creative voices across all genres.<enter />Our content is divided into Short Films and Microseries, perfect for viewing at any time and place due to their short duration ;)',

    howAccessContent_question: 'How do I access Flixxo content?',
    howAccessContent_answer: 'It\'s easy. <high>Register for free on the website or on the app (<android>Android</android> or <ios>iOS</ios>), get some Flixx, and watch the content you like the most!</high>',

    howGetFlixx_question: 'How do I get Flixx?',
    howGetFlixx_answer: 'You can easily get Flixx by <high>purchasing directly from Flixxo.</high><enter />You can also <high>earn Flixx</high> by watching ads and completing challenges.<enter />Finally, there are alternative purchasing options on <high>external exchanges</high> like Uniswap.',

    whatAreFlixx_question: 'What are Flixx?',
    whatAreFlixx_answer: 'Flixx is the crypto of our community. Thanks to the fact that we use Flixx, it is possible for creators to monetize in a direct, transparent and decentralized way every time you watch their content.<enter /><li>Every time you watch content, the creator receives your Flixx.</li><li>Every time you watch an ad, you receive Flixx.</li><li>When you complete challenges, we reward you with Flixx.</li><li>You can transfer Flixx to other users.</li><li>You can donate Flixx to content creators.</li><li>You can also buy Flixx on exchanges like and load them into your wallet.</li><enter /><flixx>Learn more about Flixx</flixx>',

    flixxGoToCreator_question: 'My flixx go directly to the creator?',
    flixxGoToCreator_answer: 'Yes! Every time you watch content, the creator receives your Flixx instantly, transparently... and without intermediaries!',

    canUploadMyContent_question: 'I\'m creator. Can I upload my content to Flixxo?',
    canUploadMyContent_answer: "Of course. Come here, we'll tell you everything.",

    flixxoDifference_question: 'What makes Flixxo different from other platforms?',
    flixxoDifference_1: 'Short-term content.',
    flixxoDifference_2: 'Transparent and direct support to the content creator.',
    flixxoDifference_3: 'Promoter of emerging and independent creators to the market.',
    flixxoDifference_4: 'Don\'t underestimate anyone, don\'t rely on an algorithm.',
    flixxoDifference_5: 'Creative ecosystem between creators and viewers.',
    flixxoDifference_6: 'Reward system.',

    producing_question: 'Flixxo produces?',
    producing_answer: 'The <high>Flixxo Originals</high> are the contents produced by Flixxo. At Flixxo Distribution, we support the creations of more than 30 <high>creators 3.0</high> from around the world, and we promote them in international markets, looking for new platforms for their distribution.',

    creators3_question: 'What are the Creators 3.0?',
    creators3_1: 'Creators 3.0 are the protagonists of a new paradigm of collaborative and decentralized creativity, typical of the technology and culture of web 3.0.',
    creators3_2: 'The fundamental value of web 3.0 is decentralization, that is, that there is no intermediary setting the rules. This gives the power back to the content creator to monetize directly.',

    doubts: 'Do you have any doubts? <mail>contact us</mail>',
  },
  beCreator: {
    title: 'Let\'s promote your content\n<high>within your community</high>',
    description: 'We support independent creators from all around the world during the production and distribution of their content.',
    cta: 'I want to be a creator 3.0',
    yourContent: 'Your content',
    benefits: {
      global: '<high>Microseries and short films</high> from anywhere in the world.',
      independent: '<high>Independent creators</high> who dare to disrupt the paradigm.',
      diverse: '<high>Diverse contents</high> covering all kinds of subjects and genres.',
      advice: '<high>You can count on our advice</high> to improve your content\'s quality.',
      reach: '<high>We can expand your reach</high> in order to get to new markets and platforms.',
      rules: '<high>Your content, your rules.</high> We are here to stand by your vision.',
    },
    steps: {
      title: 'Flixxo welcomes you with open arms',
      description: 'We understand the value and potential independent content holds, that is why the first thing you\'ll receive from us is a toast and a hug to welcome your series. From that moment on, our goal is to stand by your content.',
      reachUs: {
        title: 'Contact us',
        description: 'Show us your content',
        example_time: '{{number}} min. ago',
        example_creator_chat: 'Hello, Flixxo! I have a microseries and a short film that I would like to publish on your platform 🤍',
        example_flixxo_chat: 'Hello, creator, that\'s awesome! We love getting proposals. Let\'s work together to publish the content on Flixxo as soon as possible.',
      },
      publish: {
        title: 'We publish your content',
        description: 'Once we receive the content, we work together to publish it',
      },
      monetize: {
        title: 'Monetize with every purchase',
        description: 'Every time someone buys your content, you earn Flixx. You receive it directly, no middlemen involved',
      },
    },
    protagonist: {
      title: 'Become a Creator 3.0',
      description: 'Be at the center of your content and your community.',
    },
    dashboard: {
      title: 'Each time someone access your content, you earn Flixx.',
      description: 'Flixx is Flixxo\'s cryptocurrency. Each time someone accesses your content you\'ll receive Flixx directly into your account.',
      content_description: 'Follow up on your content\'s interactions in a detailed manner.',
      earnings: 'Your earnings',
      earnings_description: 'Review and filter the evolution of your earnings.',
    },
    footer: {
      title: 'Are you ready to become a Creator 3.0?',
      faq: 'Any questions? Most likely they are covered in our <0>faq</0>',
    },
  },
  tokenGating: {
    seo_description: 'Token Gating experience on Flixxo. Connect your wallet and unlock access to unique content with valid tokens',
    description: 'Got the token? Unlock access to unique content',
    description2: 'Discover the catalog by connecting your wallet',
    cta: 'Connect Wallet',
    walletConnected: 'Wallet connected',
    walletDisconnected: 'Wallet disconnected',
    connect: 'Connect',
    disconnect: 'Disconnect',
    step1: 'Get the token',
    step2: 'Connect your wallet',
    step3: 'Watch content',
    availableWithToken: 'Available with token',
    needToConnectWallet: 'You need to connect your wallet',
    requiredToken: 'Required token',
    waitingSignature: 'Waiting signature...',
    validating: 'Validating...',
    faqs: {
      title: 'Frequent questions',
      question_1: 'What is Token Gating?',
      answer_1: 'Token Gating is a section of exclusive content on Flixxo. Each movie, series, or video in this section is available only to users who have the necessary token. Token gating is a new way to build more meaningful connections in the independent film community.',
      question_2: 'What do I need to access it?',
      answer_2: 'To access the exclusive content, you need to have the corresponding token in your wallet, link your wallet to Flixxo, and hit play. Our system verifies that you have the token, which then allows the content to play.',
      question_3: 'How do I get tokens?',
      answer_3: 'Each piece of exclusive content has its own specific token. We\'ll tell you which token you need and where to get it in the content\'s pre-screening area.',
      question_4: 'I already have the token... now what?',
      answer_4: 'If you already have a Flixxo account, a wallet, and the token for the content you\'re interested in, all you need to do is connect your wallet to your Flixxo account. To access the chosen content, just hit play. Our system will verify that you have the token, and you\'re all set!',
      question_5: 'Can I access content without the token?',
      answer_5: 'Flixxo has a catalog of over 200 micro-series and short films that you can access freely, without needing a token. For the exclusive content in the Token Gating section, only those with the required token can access it.',
      question_6: 'How do I connect my wallet?',
      answer_6: 'You can connect your wallet in this same section using the Connect Wallet button. Choose the wallet you want to use and enter your credentials. Once your wallet is connected, you\'ll see an indicator showing that your wallet is connected. You can disconnect your wallet at any time.',
    },
    error: {
      alreadyUsed: 'The wallet is already associated to another account',
    },
  },
  old: {
    /**
     * COMMON
     */
    goToHome: 'Go to home',
    spanish: 'Spanish',
    english: 'English',
    enterEmail: 'Enter your e-mail',
    creators: 'Creators',
    download: 'Download',
    downloadApp: 'Download app',
    back: 'Go back',
    series: 'SERIES',
    min: 'min',
    faqs: 'FAQs',
    o: 'or',
    accept: 'Accept',
    cancel: 'Cancel',
    language: 'Language',
    content: 'Content',

    /**
     * MODAL
     */
    modal_enjoyYourContent_title: 'Enjoy your content',
    modal_enjoyYourContent_content: 'Get to know all the features in our app',
    modal_spendFlixx_title: 'Spend your Flixx',
    modal_spendFlixx_content: 'See the content you really want to see.',
    scanCodeQR: 'Scan the QR code and access Flixxo',
    fromYourMobilePhone: 'from your mobile phone!',
    availableOn: 'Available on',
    liveFullExperience: 'Live the full experience!',

    /**
     * NEW MESSAGES
     */
    retry: 'Retry',
    openApp: 'OPEN IN THE APPLICATION',
    backToPlay: 'Back to Play Flixxo',
    goToCreators: 'Go to Flixxo Creators',
    showDetail: 'Show detail',
    sendCode: 'Send code',
    enterPhone: 'Enter your phone number',
    enterCode: 'Enter here the code',
    newPassword: 'New password',
    confirmPassword: 'Confirm your password',
    didNotGetCode: 'Did not get the code?',
    sendAgain: 'Send again',
    enter: 'Enter',
    purchased: 'Purchased',
    watchFor: 'Watch for',
    watchSE: 'Watch S{{season}} E{{episode}}',
    subtitles: 'Subtitles',
    audio: 'Audio',
    video: 'Video',
    videos: 'Videos',
    disabledPlural: 'Disabled',
    nextEpisode: 'Next episode',
    starting: 'Starting',
    continueWatching: 'Continue watching',
    didYouEnterWrongNumber: 'Did you enter a wrong number?',
    selectOption: 'Select an option',
    next: 'Next',
    enjoyFullExperience: 'Enjoy the full expererience with our app',
    open: 'Open',
    continueOnBrowser: 'Continue on browser',

    statistics: 'Statistics',
    newFollowers: 'New Followers',
    status: 'Status',
    releaseDate: 'Release Date',
    purchases: 'Purchases',
    earnings: 'Earnings',
    price: 'Price',

    /**
     * MODALS WELCOME, LOGIN, REGISTER, RECOVERY
     */
    login: 'Sign in',
    loggingIn: 'Signing in...',
    createAccount: 'Create account',
    creatingAccount: 'Creating account...',
    goodToSeeYouAgain: 'Good to see you again!👋',
    fillInYourDetails: 'Fill in your details to enter.',
    nicknameOrEmail: 'Nickname or e-mail',
    password: 'Password',
    forgotMyPassword: 'Forgot my password',
    firstTimeInFlixxo: 'First time in Flixxo?',
    createYourAccount: 'Create your account',
    recoverPassword: 'Recover password',
    recoveringPassword: 'Recovering password...',
    enterYourMobileNumber: 'Enter your mobile number',
    mobileNumber: 'Mobile number',
    mobileInfo: 'Your phone number is required as a second security factor so that you can recover your password.',
    passwordsDoesntMatch: "Passwords doesn't match",
    enterCodeAndCreateNewPwd: 'Enter the code we sent you and create the new password',
    resendCode: 'Resend code',
    repeatPassword: 'Repeat password',
    passwordReqs: 'It must contain at least one lowercase, one uppercase, and one number. At least 8 characters long, no more than 30 characters.',
    passwordRecoverySuccessfull: 'Password succesfully recovered!',
    nowYouCanLoginWithNewPwd: 'Now you can sign in with the new password',
    great: 'Great',
    email: 'E-mail',

    createPassword: 'Create a password',
    mustContainOneLowerCase: 'Must contain at least one lower case',
    mustContainOneUpperCase: 'Must contain at least one upper case',
    mustContainOneNumber: 'Must contain at least one number',
    lengthMinMax: 'Must be {{min}} to {{max}} characters long',
    congratsHaveCreatedAnAccount: 'Congrats! \n You have created an account',
    nowYouAreFlixxer: 'Now you are a Flixxer! 🤘',
    validateAccount: 'Validate account',
    validatingAccount: 'Validating account...',
    finishAccountValidation: 'Finish the validation of your account by entering your mobile number',
    enterCodeSentTo: 'Enter the code sent to:\n{{mobileNumber}}',

    recaptcha: 'This site is protected by reCAPTCHA and the Google <0>Privacy Policy</0> and <1>Terms of Service</1> apply.',

    /**
     * SEO
     */
    seoSeriesTitle: 'Series',
    seoSeriesDescription: 'Original short-form series from all around the world',
    seoSearchTitle: 'Search',
    seoSearchDescription: 'Search for the best Flixxo content & videos',
    seoDefaultTitle: 'Flixxo',
    seoDefaultDescription: 'Short-form series and content in a web3 fashion',
    seoBeCreatorTitle: 'Be a creator',
    seoBeCreatorDescription: 'We support independent creators around the world in the production and distribution of their content.',
    seoFlixxTitle: 'Flixx, our cryptocurrency',
    seoFlixxDescription: 'Flixx is the cryptocurrency of Flixxo, use it to to watch microseries for free while supporting its creators at the same time.',

    /**
     * CREATORS
     */
    fromBeginning: 'From beginning',
    last30Days: 'Last 30 days',
    last7Days: 'Last 7 days',
    fromBeginningPhrase: 'Since you uploaded your first content',
    periodPhrase: 'From {{ start }} to {{ end }}',
    allContent: 'All content',

    noSearchResults: 'There are no results for your search',
    noSearchResultsDescription: 'Try searching another content',
    noContentYet: "You don't have any content yet",
    noContentYetDescription: 'Start to upload your content and create your own audience',
    noInternet: 'No internet connection',
    noInternetDescription: 'Check your connection and try again',
    serverError: 'Server error',
    serverErrorDescription: 'Please try again',

    searchYourVideo: 'Search your video...',
    viewOnFlixxo: 'View on Flixxo',
    published: 'Published',
    deleted: 'Deleted',
    paused: 'Paused',
    unknown: 'Unknown',

    seasonEpisode: 'S{{season}}E{{episode}}',
    earningsTooltip: 'Part of your income (purchases), is used to support the Community.',
    yourContentIsPaused: 'Your content is paused',
    knowMoreAboutContent: 'To know more about this content, contact us to content@flixxo.com',
    sendYourQuestion: 'Send your question',

    /**
     * ERROR MESSAGES
     */
    contentNotFound: 'The content you are looking for doesn\'t exist',
    wrongCredentials: 'Invalid nickname, e-mail or password.',
    emailRegistered: 'The e-mail entered is already registered.',
    emailInvalid: 'The e-mail entered has not a valid format.',
    nicknameRegistered: 'The nickname entered is already registered.',
    passwordsDontMatch: 'Passwords don\'t match',
    mobileNumberInvalid: 'The mobile number entered is not valid',
    mobileNumberRegistered: 'The mobile number entered is already registered',
    mobileNumberUnknown: 'The mobile number entered doesn\'t belong to any registered user',
    codeInvalid: 'The code entered is not valid',
    operationLimitExceeded: 'Tries limit was exceeded. Please try again later.',
    notPossibleLogInThisAccount: 'Is not possible to log in with this account.',

    /**
     * CONTENT PLAYER
     */
    seasonEpisodeHyphen: 'S{{season}}-E{{episode}}',
  },
}

export default en
