import { useEffect, useState } from 'react'
import Axios from 'axios'

import useSession from './useSession'

async function getDRMToken (contentUUID, typeDRM) {
  return await Axios.post('/drm/generatetoken', {
    contentUUID,
    type: typeDRM,
  })
}

async function getManifest (contentUUID, typeDRM) {
  return await Axios(`/contents/${contentUUID}/drm/${typeDRM}`)
}

const useDRM = (uuid, hasDRM) => {
  const { isLoggedIn } = useSession()

  const [manifest, setCountDown] = useState('')
  const [token, setToken] = useState('')

  const getManifestURL = async () => {
    try {
      const response = await getManifest(uuid, 'widevine')
      setCountDown(response.payload)
    } catch (e) {
      console.log('error getManifest', e)
    }
  }

  const getToken = async () => {
    try {
      const response = await getDRMToken(uuid, 'Widevine')
      setToken(response.payload.token)
    } catch (e) {
      console.log('error getToken', e)
    }
  }

  useEffect(() => {
    if (!isLoggedIn || !hasDRM) return

    getManifestURL()
    getToken()

    // eslint-disable-next-line
  }, []);

  return { manifest, token }
}

export default useDRM
