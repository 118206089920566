import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterSVG } from 'assets/icons/filter.svg';
import { SelectBtn } from 'ui';
import { capitalize } from 'utils/common';
var getFilters = function (t) { return ([
    {
        value: t('common:all'),
        contentType: 'all',
        label: t('content:allContents'),
    }, {
        value: t('common:microseries', { context: 'other' }),
        contentType: 'series',
        label: t('content:onlyMicroseries'),
    }, {
        value: t('common:shorts', { context: 'other' }),
        contentType: 'shorts',
        label: t('content:onlyShorts'),
    },
]); };
var SelectContentType = function (props) {
    var _a;
    var t = useTranslation().t;
    var FILTERS = getFilters(t);
    var _b = props.size, size = _b === void 0 ? 'medium' : _b, onChange = props.onChange, defaultValue = props.defaultValue, value = props.value;
    var currentDefault = (_a = FILTERS.find(function (f) { return f.contentType === defaultValue; })) !== null && _a !== void 0 ? _a : FILTERS[0];
    var currentFilter = FILTERS.find(function (f) { return f.contentType === value; });
    var handleChange = function (opt) {
        onChange(opt.contentType);
    };
    return (_jsx(SelectBtn, { options: FILTERS, size: size, selected: currentFilter, defaultValue: currentDefault, onChange: handleChange, formatSelectedValue: capitalize, icon: FilterSVG }));
};
export default SelectContentType;
