var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { USER_STATUS } from 'config/constants';
import { useApi } from 'hooks';
import i18n from 'i18n';
import { useStore } from 'stores';
export var GENDERS = [
    { label: 'male', value: 1 },
    { label: 'female', value: 2 },
    { label: 'other', value: 255 },
];
var useGetMe = function () {
    var apiFetch = useApi().apiFetch;
    var secret = useStore().secret;
    var url = '/me';
    var getMe = function () {
        return apiFetch({ url: url })
            .then(function (res) {
            var _a;
            var me = res.data;
            if ((_a = me.profile) === null || _a === void 0 ? void 0 : _a.lang) {
                localStorage.setItem('lang', me.profile.lang);
                i18n.changeLanguage(me.profile.lang);
            }
            var profileInfoFields = [
                me === null || me === void 0 ? void 0 : me.profile.birthDate,
                me === null || me === void 0 ? void 0 : me.profile.countryId,
                me === null || me === void 0 ? void 0 : me.profile.gender,
                me === null || me === void 0 ? void 0 : me.profile.realName,
            ];
            var profileInfoMissing = profileInfoFields.reduce(function (count, item) { return (item ? count : count + 1); }, 0);
            var profileInfoRequired = profileInfoFields.length;
            return (__assign(__assign({}, me), { profileInfoRequired: profileInfoRequired, profileInfoMissing: profileInfoMissing, isConfirmed: !!(me.status & USER_STATUS.confirmed), isDeleting: !!(me.status & USER_STATUS.deleting), isDeleted: !!(me.status & USER_STATUS.deleted), isBanned: !!(me.status & USER_STATUS.banned) }));
        });
    };
    var options = {
        staleTime: Infinity,
        enabled: !!secret,
    };
    return useQuery(['me'], getMe, options);
};
export default useGetMe;
