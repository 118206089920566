import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModalNew } from 'hooks';
import { Home } from 'pages';
import { ModalCreatorProfile } from 'ui';
var Creator = function () {
    var modalCreatorProfile = useModalNew();
    var nickname = useParams().nickname;
    var navigate = useNavigate();
    var handleClose = function () {
        modalCreatorProfile.close();
        navigate('/');
    };
    useEffect(function () {
        modalCreatorProfile.open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [modalCreatorProfile.isOpen && nickname && (_jsx(ModalCreatorProfile, { close: handleClose, nickname: nickname })), _jsx(Home, {})] }));
};
export default Creator;
