import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetLangs = function () {
    var apiFetch = useApi().apiFetch;
    var url = '/langs';
    var getLangs = function () {
        return apiFetch({ url: url }).then(function (res) { return res.data; });
    };
    var options = {
        staleTime: Infinity,
        cacheTime: Infinity,
        placeholderData: [],
    };
    return useQuery(['langs'], getLangs, options);
};
export default useGetLangs;
