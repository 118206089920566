import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as VideoSvg } from 'assets/icons/video-camera.svg';
import { useModalNew } from 'hooks';
import { Button } from 'ui';
import PlayerOnboarding from 'ui/ModalAuth/Onboarding/PlayerOnboard';
var ActionOnboard = function (props) {
    var isCompleted = props.isCompleted;
    var onboard = useModalNew();
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [onboard.isOpen && (_jsx(PlayerOnboarding, { onClose: onboard.close, notify: !isCompleted })), _jsx(Button, { size: 'small', variant: 'secondary', iconRight: VideoSvg, iconSize: 16, style: { backgroundColor: '#27272a' }, onClick: onboard.open, children: t('challenges:watchVideo') })] }));
};
export default ActionOnboard;
