
export const SIZES = {
  large: '48px',
  medium: '40px',
  small: '32px',
}

export const baseStyles = (theme, size, hasIcon) => ({

  container: (provided, state) => ({
    ...provided,
    flexGrow: '1',
    color: theme.old.colors.gray[100],
  }),

  control: (provided, state) => {
    const { isLeftContact } = state.selectProps

    const borderRadius = isLeftContact
      ? '0 6px 6px 0'
      : 8

    return {
      ...provided,
      boxShadow: 'none',
      border: `1px solid ${theme.old.colors.gray[700]}`,
      borderRadius,
      height: SIZES[size],
      minHeight: SIZES[size],
      cursor: 'pointer',
    }
  },

  singleValue: (provided, state) => ({ ...provided }),
  dropdownIndicator: (provided, state) => ({ ...provided }),

  option: (provided, state) => {
    const bg = state.isSelected
      ? '#7F39FA'
      : 'transparent'

    return ({
      ...provided,
      cursor: 'pointer',
      width: '100%',
      transition: 'all 100ms',
      // whiteSpace: 'nowrap',
      borderRadius: 8,
      marginBottom: 3,
      backgroundColor: bg,

      ':hover': {
        backgroundColor: bg,
      },
    })
  },

  menuList: (provided, st) => ({
    ...provided,
    padding: 0,
  }),

  menu: (provided, state) => ({
    ...provided,
    minWidth: 'fit-content',
    padding: 8,
    borderRadius: 8,
    marginTop: 8,
    right: 0, // this maybe should be parametrizable
  }),

  placeholder: (provided, state) => ({ ...provided, color: '#A1A1AA' }),
  input: (provided, state) => ({ ...provided }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: hasIcon ? 35 : 8,
  }),
})
