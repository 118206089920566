import { PLAYER_ID } from 'config/constants'

import('videojs-contrib-eme')
const videojs = () => import('video.js')


const useVideojsSamples = function () {
  function processVideo (manifest, token) {
    // const manifest = "https://dev-flx-drm-content.s3.amazonaws.com/9d43a5784353f9290106d285b8d3631084590d25_dash.mpd?AWSAccessKeyId=AKIAZIL5AZKVBK7EMLU4&Expires=1640114608&Signature=2rkkJ7OQMZ%2FVCi5f0ksXe0W%2FFes%3D"
    // const token = "eyJkcm1fdHlwZSI6IldpZGV2aW5lIiwidXNlcl9pZCI6ImY2OGZjMzkzLWFmYmUtNGZiNi04NjE4LWZmNTVjM2ZmZjg1MyIsInNpdGVfaWQiOiI1RzVCIiwiY2lkIjoiZjk1OTEwMWEtY2Y2ZC00YjA2LWJiZDEtYmI2ODQwNzQzNzI2IiwicG9saWN5IjoiSmcxRXc2d0tBOWNGR0xVbHBqeURoVVQwVCtBbXlTQWNlc3owZVpTbXFaS3d5OGZpbzkxd1pDMVZEaHRWd0JiVEZqYWQxQ3NRNEtHNXExV09XK2FtY21IcEcrL1pBWWFOMEVyRUFYb0hJcy9iZjd2VFE0K2NDckcwaHVCOW1zeThZaTBIUzRvZ1JCTVI0VmczRTVkaXVybDZ1VkN0di9CckhKZlBEQy9qMjhsWUYyNU5vUk5sTktvVXBSa0lGNHB1IiwidGltZXN0YW1wIjoiMjAyMS0xMi0yMVQxODoyMzoyOFoiLCJoYXNoIjoibENOU3AxelpINURNQmtDRHAvTFJiVGVJRng3MmsxWFQ3cWpyZ29rTlVkcz0ifQ=="

    // var browser = 'Non-DRM browser';
    let drmType = 'No DRM'

    const dashUri = manifest

    // Create and set the license tokens when you test your own content.
    const widevineToken = token
    const playreadyToken = widevineToken

    const licenseUri = `https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=${widevineToken}`

    // Replace the DEMO site ID with yours when you test your own FPS content.
    // var fairplayCertUri = 'https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=DEMO'; // for base64 encoded binary cert data
    // var fairplayCertDerUri = 'https://license-global.pallycon.com/ri/fpsCert.do?siteId=DEMO'; // for cert .der file download

    // Detect the browser and set proper DRM type
    function checkBrowser () {
      let agent = navigator.userAgent.toLowerCase()
      const name = navigator.appName
      let browser

      if (name === 'Microsoft Internet Explorer' || agent.includes('trident') || agent.includes('edge/')) {
        browser = 'ie'
        if (name === 'Microsoft Internet Explorer') { // IE old version (IE 10 or Lower)
          agent = /msie ([0-9]{1,}[.0-9]{0,})/.exec(agent)
          // browser += parseInt(agent[1]);
        } else if (agent.includes('edge/')) { // Edge
          browser = 'Edge'
        }
        drmType = 'PlayReady'
      } else if (agent.includes('safari')) { // Chrome or Safari
        if (agent.includes('opr')) { // Opera
          browser = 'Opera'
          drmType = 'Widevine'
        } else if (agent.includes('whale')) { // Chrome
          browser = 'Whale'
          drmType = 'Widevine'
        } else if (agent.includes('edg/') || agent.includes('Edge/')) { // Chrome
          browser = 'Edge'
          drmType = 'PlayReady'
        } else if (agent.includes('chrome')) { // Chrome
          browser = 'Chrome'
          drmType = 'Widevine'
        } else { // Safari
          browser = 'Safari'
          drmType = 'FairPlay'
        }
      } else if (agent.includes('firefox')) { // Firefox
        browser = 'firefox'
        drmType = 'Widevine'
      }

      // The below three lines are for the sample code only. May need to be removed.
      const result = 'Running in ' + browser + '. ' + drmType + ' supported.'
      // document.getElementById("browserCheckResult").innerHTML = result;

      return browser
    }

    const players = videojs.getAllPlayers()

    /** removing all player instances */
    players.forEach(eachPlayer => eachPlayer.dispose())

    /** creating a new player instance from scratch */
    const player = videojs(PLAYER_ID)

    function configureDRM () {
      player.ready(function () {
        let playerConfig
        if (typeof player.eme === 'function') {
          player.eme()
        }

        if (drmType === 'FairPlay') {
          // playerConfig = {
          //     src: hlsUri,
          //     type: 'application/x-mpegurl',
          //     keySystems: {
          //         'com.apple.fps.1_0': {
          //             getCertificate: function (emeOptions, callback) {
          //                 videojs.xhr({
          //                     url: fairplayCertUri,
          //                     method: 'GET',
          //                 }, function (err, response, responseBody) {
          //                     if (err) {
          //                         callback(err)
          //                         return
          //                     }
          //                     callback(null, base64DecodeUint8Array(responseBody));
          //                 })
          //             },
          //             getContentId: function (emeOptions, initData) {
          //                 const contentId = arrayToString(initData);
          //                 return contentId.substring(contentId.indexOf('skd://') + 6);
          //             },
          //             getLicense: function (emeOptions, contentId, keyMessage, callback) {
          //                 videojs.xhr({
          //                     url: licenseUri,
          //                     method: 'POST',
          //                     responseType: 'text',
          //                     body: 'spc=' + base64EncodeUint8Array(keyMessage),
          //                     headers: {
          //                         'Content-type': 'application/x-www-form-urlencoded',
          //                         'pallycon-customdata-v2': fairplayToken
          //                     }
          //                 }, function (err, response, responseBody) {
          //                     if (err) {
          //                         callback(err)
          //                         return
          //                     }
          //                     callback(null, base64DecodeUint8Array(responseBody))
          //                 })
          //             }
          //         }
          //     }
          // };

        } else if (drmType === 'PlayReady') {
          playerConfig = {
            src: dashUri,
            type: 'application/dash+xml',
          }

          playerConfig.keySystemOptions = []
          playerConfig.keySystemOptions.push({
            name: 'com.microsoft.playready',
            options: {
              serverURL: licenseUri,
              httpRequestHeaders: {
                'pallycon-customdata-v2': playreadyToken,
              },
            },
          })
        } else if (drmType === 'Widevine') {
          playerConfig = {
            src: dashUri,
            type: 'application/dash+xml',
            keySystems: {
              'com.widevine.alpha': licenseUri,
            },
          }
        } else {
          console.log('No DRM supported in this browser')
        }

        player.src(playerConfig)
      })
    }

    // window.widevineToken = document.getElementById('token').value;
    window.widevineToken = widevineToken

    checkBrowser()
    player.ready(function () {
      configureDRM()
    })
  }

  return { processVideo }
}

export default useVideojsSamples
