import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetSimilars = function (uuid) {
    var apiFetch = useApi().apiFetch;
    var url = "/suggestions/contents/similar/".concat(uuid);
    var getSimilars = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    var options = {
        staleTime: 60000 * 10, // 30 min
        // enabled,
    };
    return useQuery(['similars', uuid, 'lang'], getSimilars, options);
};
export default useGetSimilars;
