var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { LinkLang } from 'ui';
import { Container, IconWrapper, Title } from './styles';
var Helper = function (props) {
    var Icon = props.icon, title = props.title, to = props.to, onClick = props.onClick;
    var ref = useRef();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var linkProps = to
        ? { to: to, as: LinkLang }
        : { onClick: onClick, role: 'button' };
    var toggleOpen = function () {
        setIsOpen(function (prev) { return !prev; });
    };
    var handleMouseEnter = function () {
        if (isMobile)
            return;
        setIsOpen(true);
    };
    var handleMouseLeave = function () {
        if (isMobile)
            return;
        setIsOpen(false);
    };
    useEffect(function () {
        if (!isOpen)
            return;
        var handleClose = function (e) {
            if (ref.current.contains(e.target))
                return;
            setIsOpen(false);
        };
        document.addEventListener('mouseup', handleClose);
        return function () {
            document.removeEventListener('mouseup', handleClose);
        };
    }, [isOpen]);
    return (_jsxs(Container, { onMouseLeave: handleMouseLeave, onMouseEnter: handleMouseEnter, "$isOpen": isOpen, ref: ref, children: [_jsx(IconWrapper, { onClick: toggleOpen, children: _jsx(Icon, { width: 32, height: 32 }) }), _jsx(Title, __assign({}, linkProps, { children: title }))] }));
};
export default Helper;
