const SvgComponent = (props) => (
  <svg
    width={8}
    height={4}
    viewBox='0 0 8 4'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m1 3.5 3-3 3 3'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SvgComponent
