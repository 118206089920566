import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useResponsive } from 'hooks';
import { Modal } from 'templates';
import { Button, CarouselCard, ModalPortal, Pill, Skeleton, Text } from 'ui';
import { ReactComponent as CloseSvg } from 'assets/icons/exit.svg';
import { ReactComponent as FlixxoLogoColor } from 'assets/brand/flixxo-logo-color.svg';
import { getContentUrl } from 'utils/contents';
import { getMedia } from 'utils/thumbnails';
import { formatDuration } from 'utils/duration';
import { contentApi } from 'api';
import { GAHandler } from 'utils/GA';
var ModalPostRoll = function (props) {
    var _a;
    var close = props.close, contentUuid = props.contentUuid;
    var _b = contentApi.useGetSimilars(contentUuid), data = _b.data, isLoadingSimilars = _b.isLoading;
    var _c = useResponsive(), isMobile = _c.isMobile, isMobileBig = _c.isMobileBig, isTabBig = _c.isTabBig;
    var t = useTranslation().t;
    var similars = (_a = data === null || data === void 0 ? void 0 : data.contents) !== null && _a !== void 0 ? _a : [];
    var handleClose = function () {
        close();
        GAHandler('postroll_close');
    };
    useEffect(function () {
        GAHandler('postroll_presented');
    }, []);
    return (_jsx(ModalPortal, { children: _jsxs(Modal, { width: 1320, padding: isMobileBig ? '16px 30px 30px' : 30, modalStyle: { maxWidth: isMobileBig ? 'unset' : '90%' }, containerStyle: {
                height: isMobileBig ? '100dvh' : 'fit-content',
                maxHeight: isMobileBig ? 'unset' : '80vh',
            }, children: [_jsx("div", { style: { display: 'flex', justifyContent: 'end' }, children: _jsx(Button, { size: 'small', iconLeft: CloseSvg, iconSize: 16, variant: 'tertiary', onClick: handleClose }) }), _jsxs("div", { style: {
                        border: '2px solid #C6AAF9',
                        backgroundColor: 'black',
                        borderRadius: 8,
                        height: 85,
                        marginTop: isMobileBig ? 6 : 26,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        gap: 14,
                        padding: 20,
                    }, children: [_jsx(FlixxoLogoColor, { style: {
                                flexShrink: 0,
                                width: isTabBig ? 63 : 66,
                            } }), _jsx("div", { style: {
                                position: !isTabBig ? 'absolute' : 'unset',
                                left: '50%',
                                top: '50%',
                                transform: !isTabBig ? 'translate(-50%, -50%)' : 'unset',
                                flexGrow: 1,
                            }, children: _jsx(Text, { size: 18, weight: 600, color: '#C7AAFA', align: 'center', style: {
                                    margin: '0 auto',
                                    whiteSpace: isMobile ? 'pre-line' : 'nowrap',
                                }, children: t('anteroom:postroll.title') }) })] }), _jsxs("div", { style: {
                        display: isMobileBig ? 'flex' : 'grid',
                        flexDirection: 'column',
                        gap: isMobileBig ? 12 : '2vw',
                        position: 'relative',
                        margin: isMobileBig ? '12px auto 0' : '30px 0 0',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        maxWidth: !isMobileBig ? 'unset' : 300,
                    }, children: [isLoadingSimilars && (_jsxs(_Fragment, { children: [_jsx(Skeleton, {}), _jsx(Skeleton, {}), _jsx(Skeleton, {})] })), similars === null || similars === void 0 ? void 0 : similars.slice(0, 3).map(function (content) {
                            var pill = content.contentType === 2
                                ? t('common:microseries', { count: 1 })
                                : t('common:shorts', { count: 1 });
                            var info = content.contentType === 2
                                ? content.numSeasons + ' ' + t('common:seasons', { count: Number(content.numSeasons) })
                                : formatDuration(content.duration);
                            return (_jsxs("div", { style: {
                                    position: 'relative',
                                    height: 'fit-content',
                                }, children: [_jsx(CarouselCard, { imgSrc: getMedia({ media: content.media }), to: getContentUrl(content.contentType, content.uuid), isHoverEffect: false, title: content.title, onClick: function () {
                                            GAHandler('postroll_content');
                                            close();
                                        } }), _jsxs("div", { style: {
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'end',
                                            justifyContent: 'space-between',
                                            background: 'linear-gradient(0, black, transparent)',
                                            borderRadius: 6,
                                            height: '45%',
                                            padding: isMobileBig ? 12 : isTabBig ? 8 : 12,
                                            pointerEvents: 'none',
                                        }, children: [_jsx(Pill, { value: pill, size: isTabBig ? 'sm' : 'md' }), _jsx(Text, { size: 14, color: 'headline', children: info })] })] }, content.uuid));
                        })] })] }) }));
};
export default ModalPostRoll;
