var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
export var creators = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:active {\n    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:active {\n    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.colors.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[900];
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.white;
});
export var text = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: transparent;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding: 0px;\n  height: fit-content;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  background-color: transparent;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding: 0px;\n  height: fit-content;\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.size.normal;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.weight.semibold;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.purple[600];
});
export var flameInverted = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: transparent;\n\n  border-radius: 8px;\n  padding: 2px;\n\n  border: 1.5px solid #CA1BC7;\n\n  &:hover{\n    border-color: white;\n  }\n"], ["\n  background: transparent;\n\n  border-radius: 8px;\n  padding: 2px;\n\n  border: 1.5px solid #CA1BC7;\n\n  &:hover{\n    border-color: white;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
