import { useQuery } from '@tanstack/react-query';
import { useApi, useSession } from 'hooks';
var useGetRewards = function () {
    var apiFetch = useApi().apiFetch;
    var isLoggedIn = useSession().isLoggedIn;
    var url = 'me/rewards';
    var getRewards = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    var options = {
        staleTime: 0,
        enabled: isLoggedIn,
    };
    return useQuery(['rewards'], getRewards, options);
};
export default useGetRewards;
