var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card, Pill, Text } from 'ui';
var Cover = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  aspect-ratio: 16 / 9;\n  background: ", ";\n  background-size: 100%;\n  background-position: center;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: end;\n  position: relative;\n\n  &::after {\n    content: '';\n    background: linear-gradient(0deg, #18181B 0%, #000000 35%, rgba(0, 0, 0, 0) 100%);\n    height: 40%;\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: -1px;\n  }\n"], ["\n  aspect-ratio: 16 / 9;\n  background: ", ";\n  background-size: 100%;\n  background-position: center;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: end;\n  position: relative;\n\n  &::after {\n    content: '';\n    background: linear-gradient(0deg, #18181B 0%, #000000 35%, rgba(0, 0, 0, 0) 100%);\n    height: 40%;\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: -1px;\n  }\n"])), function (_a) {
    var $src = _a.$src;
    return "url(".concat($src, ")");
});
var CardContentSuggestion = function (props) {
    var to = props.to, cover = props.cover, duration = props.duration, body = props.body, pill = props.pill, withToken = props.withToken;
    var t = useTranslation().t;
    return (_jsxs(Card, { style: { overflow: 'hidden' }, mode: 'link', to: to, children: [_jsx(Cover, { "$src": cover, children: _jsx("div", { style: {
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'end',
                    }, children: _jsxs("div", { style: {
                            position: 'relative',
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto 18px',
                            gap: 6,
                        }, children: [_jsx(Pill, { size: 'sm', value: pill }), _jsx(Text, { color: '#71717A', children: "|" }), _jsx(Text, { size: 'xs', color: 'headline', children: duration })] }) }) }), _jsxs("div", { style: {
                    padding: '2px 12px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 12,
                    flexGrow: 1,
                }, children: [_jsx(Text, { size: 'xs', align: 'center', lineClamp: 3, children: body }), withToken && (_jsx(Text, { size: 'xs', weight: 'medium', style: {
                            background: 'linear-gradient(118.36deg, #DC02FF 0%, #FFD600 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            width: 'fit-content',
                            margin: '0 auto',
                        }, children: t('tokenGating:availableWithToken') }))] })] }));
};
export default CardContentSuggestion;
var templateObject_1;
