import { useState } from 'react';
var useModalNew = function (defaultValue) {
    if (defaultValue === void 0) { defaultValue = false; }
    var _a = useState(defaultValue), isOpen = _a[0], setIsOpen = _a[1];
    var open = function () { setIsOpen(true); };
    var close = function () { setIsOpen(false); };
    var toggle = function () { setIsOpen(function (prev) { return !prev; }); };
    var modal = {
        isOpen: isOpen,
        open: open,
        close: close,
        toggle: toggle,
    };
    return modal;
};
export default useModalNew;
