var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { LinkLang, Spinner } from '..';
import { ButtonStyled } from './styles';
var Button = function (props) {
    var children = props.children, name = props.name, _a = props.variant, variant = _a === void 0 ? 'primary' : _a, _b = props.size, size = _b === void 0 ? 'medium' : _b, isFull = props.isFull, _c = props.onClick, onClick = _c === void 0 ? function () { } : _c, to = props.to, className = props.className, disabled = props.disabled, isLoading = props.isLoading, IconLeft = props.iconLeft, IconRight = props.iconRight, _d = props.iconSize, iconSize = _d === void 0 ? 24 : _d, openNewTab = props.openNewTab, _e = props.weight, weight = _e === void 0 ? 'normal' : _e, _f = props.borderRadius, borderRadius = _f === void 0 ? 8 : _f, animation = props.animation, onAnimationStart = props.onAnimationStart, onAnimationEnd = props.onAnimationEnd, style = props.style, id = props.id, rest = __rest(props, ["children", "name", "variant", "size", "isFull", "onClick", "to", "className", "disabled", "isLoading", "iconLeft", "iconRight", "iconSize", "openNewTab", "weight", "borderRadius", "animation", "onAnimationStart", "onAnimationEnd", "style", "id"]);
    var _g = useState(false), addAnimation = _g[0], setAddAnimation = _g[1];
    var isOnlyIcon = !children && (!!IconLeft || !!IconRight);
    var isLink = !!to;
    var linkProps = isLink && openNewTab
        ? { target: '_blank', rel: 'noopener noreferrer' }
        : {};
    var handleClick = function (e) {
        if (disabled || isLoading) {
            e.preventDefault();
            return;
        }
        onClick(e);
        if (animation) {
            setAddAnimation(true);
            onAnimationStart === null || onAnimationStart === void 0 ? void 0 : onAnimationStart();
            setTimeout(function () {
                setAddAnimation(false);
                onAnimationEnd === null || onAnimationEnd === void 0 ? void 0 : onAnimationEnd();
            }, 3000);
        }
    };
    return (_jsx(ButtonStyled, __assign({ id: id, className: className, name: name, size: size, "$isFull": isFull, "$variant": variant, "$animation": addAnimation ? animation : undefined, onClick: handleClick, to: to, as: isLink ? LinkLang : 'button' }, linkProps, { disabled: disabled, "$isLoading": isLoading, "$isIcon": isOnlyIcon, "$weight": weight, "$borderRadius": borderRadius, style: style }, rest, { children: isLoading ? (_jsxs(_Fragment, { children: [children, _jsx(Spinner, {})] })) : (_jsxs(_Fragment, { children: [IconLeft && (_jsx(IconLeft, { height: iconSize, width: iconSize })), children, IconRight && (_jsx(IconRight, { height: iconSize, width: iconSize }))] })) })));
};
export default Button;
