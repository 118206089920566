import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userApi } from 'api';
import { ReactComponent as CloseSvg } from 'assets/icons/exit.svg';
import { ReactComponent as GiftSvg } from 'assets/icons/gift.svg';
import { ReactComponent as ShareSvg } from 'assets/icons/share.svg';
import { ReactComponent as StarFillSvg } from 'assets/icons/star-fill.svg';
import { ReactComponent as StarOutlineSvg } from 'assets/icons/star-outline.svg';
import patternSvg from 'assets/profile/pattern.svg';
import { useModalNew, useResponsive, useSession } from 'hooks';
import useIsFollowing from 'hooks/useIsFollowing';
import { useModalAuth } from 'stores';
import { Modal } from 'templates';
import { Anteroom, Avatar, Button, CarouselBase, ModalDonateCreator, ModalPortal, SelectContentType, Skeleton, Text, TooltipAction, } from 'ui';
import { GAHandler } from 'utils/GA';
import { getCover } from 'utils/thumbnails';
import { getAvatarSrc } from 'utils/media';
var ModalCreatorProfile = function (props) {
    var _a;
    var close = props.close, nickname = props.nickname;
    var t = useTranslation().t;
    var isLoggedIn = useSession().isLoggedIn;
    var _b = useResponsive(), isMobile = _b.isMobile, isMobileBig = _b.isMobileBig;
    var modalDonateCreator = useModalNew();
    var openWelcome = useModalAuth().openWelcome;
    var _c = useState('all'), contentType = _c[0], setContentType = _c[1];
    var me = userApi.useGetMe().data;
    var _d = userApi.useGetCreator(nickname), creator = _d.data, isLoadingCreator = _d.isLoading, isErrorCreator = _d.isError;
    var _e = userApi.useFollow(creator === null || creator === void 0 ? void 0 : creator.uuid), follow = _e.follow, unfollow = _e.unfollow, isLoadingFollow = _e.isLoading;
    var _f = useIsFollowing(), isFollowingFn = _f.isFollowing, isFetchingFolloweds = _f.isFetchingFolloweds;
    var handleFollow = function () {
        if (!creator)
            return;
        follow(creator.id);
    };
    var handleUnfollow = function () {
        if (!creator)
            return;
        unfollow(creator.id);
    };
    var contentTypes = creator === null || creator === void 0 ? void 0 : creator.contents.map(function (content) { return content.contentType; });
    var differentContentTypes = (new Set(contentTypes)).size > 1;
    var showFilter = differentContentTypes;
    var isFollowing = !!creator && isLoggedIn
        ? isFollowingFn(creator.uuid)
        : false;
    var content = isLoadingCreator
        ? [1, 2, 3].map(function (i) { return (_jsx("div", { style: { width: '80vw', maxWidth: 343 }, children: _jsx(Skeleton, {}, i) }, i)); })
        : creator === null || creator === void 0 ? void 0 : creator.contents.filter(function (item) {
            if (contentType === 'series' && item.contentType === 2)
                return item;
            if (contentType === 'shorts' && item.contentType === 3)
                return item;
            if (contentType === 'all')
                return item;
        }).map(function (content) {
            var cover = getCover({ media: content.media, size: 'xcover' });
            var url = content.contentType === 3
                ? "content/".concat(content.uuid)
                : "series/".concat(content.uuid);
            return (_jsx("div", { style: {
                    width: '80vw',
                    minHeight: 172,
                    height: '100%',
                    maxWidth: 343,
                }, children: _jsx(Anteroom.Carousel.Slide, { onClick: close, to: url, cover: cover, variant: 'secondary', title: content.title, body: content.body, duration: content.duration }, content.uuid) }, content.uuid));
        });
    if (isErrorCreator) {
        return (_jsx(ModalPortal, { children: _jsxs(Modal, { width: 597, height: 500, borderRadius: 16, padding: isMobileBig ? '0 0 24px' : '0 0 39px', children: [_jsx("div", { style: { position: 'absolute', top: 24, right: 24 }, children: _jsx(Button, { variant: 'secondary', size: 'small', iconRight: CloseSvg, iconSize: 16, onClick: close }) }), _jsx("div", { style: { height: 164, background: "url(".concat(patternSvg, ")") } }), _jsx(Text
                    // color='headline'
                    , { 
                        // color='headline'
                        align: 'center', style: { border: '1px solid transparent', width: 300, margin: '60px auto 0' }, size: 18, children: t('modals:creatorProfile.notFound') })] }) }));
    }
    if (isErrorCreator) {
        return (_jsx(ModalPortal, { children: _jsxs(Modal, { width: 597, height: 500, borderRadius: 16, padding: isMobileBig ? '0 0 24px' : '0 0 39px', children: [_jsx("div", { style: { position: 'absolute', top: 24, right: 24 }, children: _jsx(Button, { variant: 'secondary', size: 'small', iconRight: CloseSvg, iconSize: 16, onClick: close }) }), _jsx("div", { style: { height: 164, background: "url(".concat(patternSvg, ")") } }), _jsx(Text
                    // color='headline'
                    , { 
                        // color='headline'
                        align: 'center', style: { border: '1px solid transparent', width: 300, margin: '60px auto 0' }, size: 18, children: t('modals:creatorProfile.notFound') })] }) }));
    }
    return (_jsxs(_Fragment, { children: [modalDonateCreator.isOpen && (_jsx(ModalDonateCreator, { close: modalDonateCreator.close })), _jsx(ModalPortal, { children: _jsxs(Modal, { width: 597, height: 'fit-content', borderRadius: 16, padding: isMobileBig ? '0 0 24px' : '0 0 39px', children: [_jsx("div", { style: { position: 'absolute', top: 24, right: 24 }, children: _jsx(Button, { variant: 'secondary', size: 'small', iconRight: CloseSvg, iconSize: 16, onClick: close }) }), _jsx("div", { style: { height: 164, background: "url(".concat(patternSvg, ")") } }), _jsxs("div", { style: {
                                margin: isMobileBig ? '-38px 16px 0' : '-43px 24px 0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 16,
                            }, children: [_jsxs("div", { style: {
                                        display: 'flex',
                                        alignSelf: 'start',
                                        alignItems: isMobile ? 'start' : 'center',
                                        flexDirection: isMobile ? 'column' : 'row',
                                        gap: 16,
                                        flexGrow: 1,
                                        minWidth: 'max-content',
                                    }, children: [_jsx(Avatar, { isLoading: !creator, src: getAvatarSrc(creator === null || creator === void 0 ? void 0 : creator.Profile, 'h164'), style: {
                                                height: isMobileBig ? 72 : 80,
                                                width: isMobileBig ? 72 : 80,
                                                border: 'none',
                                            } }), _jsxs("div", { children: [_jsx(Text, { size: 'xl', color: 'headline', children: creator === null || creator === void 0 ? void 0 : creator.nickname }), _jsxs(Text, { size: 'sm', children: [creator === null || creator === void 0 ? void 0 : creator.contents.length, " ", t('common:contents')] })] })] }), _jsxs("div", { style: {
                                        alignSelf: 'start',
                                        display: 'flex',
                                        flexDirection: isMobileBig ? 'column' : 'row',
                                        gap: 12,
                                        marginTop: 40,
                                        transform: 'translate(0, -50%)',
                                    }, children: [_jsx(Button, { iconLeft: GiftSvg, iconSize: 24, size: 'small', onClick: modalDonateCreator.open, children: _jsx(Text, { as: 'span', color: 'headline', size: 14, weight: 500, children: t('common:support_creators') }) }), _jsxs("div", { style: { display: 'flex', gap: 16 }, children: [_jsx(Button, { size: 'small', iconSize: 16, iconLeft: isFollowing ? StarFillSvg : StarOutlineSvg, variant: 'secondary', onClick: isLoggedIn
                                                        ? isFollowing ? handleUnfollow : handleFollow
                                                        : openWelcome, disabled: !isLoadingFollow && isLoadingCreator, isLoading: isLoadingFollow || isFetchingFolloweds, style: {
                                                        color: '#D9D9D9',
                                                        flexGrow: isMobileBig ? 1 : 0,
                                                    } }), _jsx(TooltipAction, { children: function (tooltip) { return (_jsx(Button, { size: 'small', iconLeft: ShareSvg, iconSize: 16, variant: 'secondary', onClick: function () {
                                                            tooltip.show();
                                                            var shareUrl = location.origin + '/creator/' + nickname;
                                                            navigator.clipboard.writeText(shareUrl);
                                                            GAHandler('ui_share_creator', {
                                                                creator: nickname,
                                                                isHimself: nickname === (me === null || me === void 0 ? void 0 : me.nickname),
                                                            });
                                                        }, style: {
                                                            color: '#D9D9D9',
                                                            flexGrow: isMobileBig ? 1 : 0,
                                                        } })); } })] })] })] }), _jsx("div", { style: {
                                margin: isMobileBig ? '16px 16px 0' : '16px 24px 0',
                                maxHeight: 80,
                                overflowY: 'auto',
                                overflowX: 'visible',
                                overflowWrap: 'break-word',
                            }, children: _jsx(Text, { size: 'sm', children: (_a = creator === null || creator === void 0 ? void 0 : creator.Profile) === null || _a === void 0 ? void 0 : _a.bio }) }), _jsxs("div", { style: {
                                margin: isMobileBig ? 16 : '16px 24px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }, children: [_jsx(Text, { weight: 'semibold', color: 'headline', children: t('modals:creatorProfile.content') }), showFilter && (_jsx(SelectContentType, { onChange: setContentType }))] }), _jsx(CarouselBase, { slidesPerView: 'auto', isFreeMode: true, offsetRight: isMobileBig ? 16 : 24, spaceBetween: 16, simulateTouch: true, children: content })] }) })] }));
};
export default ModalCreatorProfile;
