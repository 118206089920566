import { useQuery } from '@tanstack/react-query'

import { useApi, useSession } from 'hooks'


const useGetPurchased = () => {
  const { isLoggedIn } = useSession()

  const { apiFetch } = useApi()

  const url = '/me/contents/purchased'

  const getContent = () => {
    return apiFetch({ url })
      .then(res => res.data)
  }

  const options = {
    staleTime: 60000 * 30, // 30 min
    placeholderData: null,
    enabled: isLoggedIn,
  }

  return useQuery(
    ['purchases'],
    getContent,
    options,
  )
}

export default useGetPurchased
