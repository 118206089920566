var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import scriptjs from 'scriptjs';
import { useDetectAdBlock } from 'adblock-detect-react';
import { adsApi } from 'api';
import { environment } from 'config/environment';
import { useOpen } from 'hooks';
import { GAHandler } from 'utils/GA';
import ModalAdBlock from './ModalAdBlocker';
var PlayerAdManager = function (_a) {
    var close = _a.close, onReward = _a.onReward;
    var adBlockDetected = useDetectAdBlock();
    var adBlockRef = useRef();
    adBlockRef.current = adBlockDetected;
    var adRef = useRef(null);
    var slotRef = useRef(null);
    var timeoutRef = useRef(null);
    var _b = adsApi.useGetAd(), ad = _b.data, resetAd = _b.remove;
    var resetNextReward = adsApi.useGetNextReward().remove;
    var postWatchedAd = adsApi.usePostWatchedAd().postWatchedAd;
    var modalAdBlock = useOpen();
    var _c = useState(false), callEnd = _c[0], setCallEnd = _c[1];
    var onSlotReady = function (event) {
        clearTimeout(timeoutRef.current);
        GAHandler('ad_presented');
        event.makeRewardedVisible();
    };
    var onSlotClosed = function () {
        window.googletag.destroySlots([slotRef.current]);
        GAHandler('ad_unfinished');
        close();
    };
    var onRewardGranted = function () {
        GAHandler('ad_finished');
        postWatchedAd({ id: adRef.current.id })
            .then(function () {
            window.googletag.destroySlots([slotRef.current]);
            setCallEnd(true);
        });
    };
    var logic = function () {
        var googletag = window.googletag || { cmd: [] };
        if (!window.googletag) {
            modalAdBlock.open();
            return;
        }
        googletag.cmd.push(function () {
            slotRef.current = googletag.defineOutOfPageSlot(environment.AD_MANAGER_TAG_REWARED, googletag.enums.OutOfPageFormat.REWARDED);
            // Slot returns null if the page or device does not support rewarded ads.
            if (slotRef.current) {
                slotRef.current.addService(googletag.pubads());
                googletag.pubads().addEventListener('rewardedSlotReady', onSlotReady);
                googletag.pubads().addEventListener('rewardedSlotClosed', onSlotClosed);
                googletag.pubads().addEventListener('rewardedSlotGranted', onRewardGranted);
                // googletag.pubads().disableInitialLoad()
                googletag.pubads().enableSingleRequest();
                googletag.pubads().collapseEmptyDivs();
                googletag.enableServices();
                googletag.display(slotRef.current);
            }
            else {
                console.log('Page does NOT support rewarded ads');
            }
        });
    };
    var startAd = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            url = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
            if ((_a = window.googletag) === null || _a === void 0 ? void 0 : _a.pubads) {
                (_c = (_b = window.googletag) === null || _b === void 0 ? void 0 : _b.pubads()) === null || _c === void 0 ? void 0 : _c.refresh();
                logic();
            }
            else {
                scriptjs.get(url, logic);
            }
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        close();
        modalAdBlock.close();
    };
    useEffect(function () {
        if (!ad)
            return;
        adRef.current = ad;
    }, [ad]);
    useEffect(function () {
        setTimeout(function () {
            if (adBlockRef.current) {
                modalAdBlock.open();
            }
            else {
                startAd();
            }
        }, 150);
        return function () {
            var _a, _b, _c, _d, _e;
            resetAd();
            resetNextReward();
            if (!((_b = (_a = window.googletag) === null || _a === void 0 ? void 0 : _a.pubads) === null || _b === void 0 ? void 0 : _b.call(_a).removeEventListener)) {
                return;
            }
            (_c = window.googletag) === null || _c === void 0 ? void 0 : _c.pubads().removeEventListener('rewardedSlotReady', onSlotReady);
            (_d = window.googletag) === null || _d === void 0 ? void 0 : _d.pubads().removeEventListener('rewardedSlotClosed', onSlotClosed);
            (_e = window.googletag) === null || _e === void 0 ? void 0 : _e.pubads().removeEventListener('rewardedSlotGranted', onRewardGranted);
        };
    }, []);
    /**
     * The goal for this effect is that
     * the balance be updated when the
     * onEnd is called.
     */
    useEffect(function () {
        if (callEnd) {
            close();
            onReward();
            setCallEnd(false);
        }
    }, [callEnd]);
    return (_jsx(_Fragment, { children: modalAdBlock.isOpen && (_jsx(ModalAdBlock, { close: handleClose })) }));
};
export default PlayerAdManager;
