
import { useQuery } from '@tanstack/react-query'

import { useApi } from 'hooks'

const useGetNextReward = () => {
  const { apiFetch } = useApi()

  const url = '/advertisements/reward'

  const getNextReward = () => {
    return apiFetch({ url })
      .then(res => res.data)
  }

  const options = {
    placeholderData: null,
    refetchOnWindowFocus: false,
  }

  return useQuery(['nextReward'], getNextReward, options)
}

export default useGetNextReward
