import { useQuery } from '@tanstack/react-query';
import { useApi, useSession } from 'hooks';
var useGetBalance = function () {
    var apiFetch = useApi().apiFetch;
    var isLoggedIn = useSession().isLoggedIn;
    var url = '/me/balance';
    var getBalance = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data.amount; });
    };
    var options = {
        staleTime: 30000, // 30 sec
        placeholderData: '0.00',
        enabled: isLoggedIn,
    };
    return useQuery(['balance'], getBalance, options);
};
export default useGetBalance;
