import { useState } from 'react'

const useSteps = (initial = 0) => {
  const [step, setStep] = useState(initial)

  const nextStep = () => { setStep(prev => prev + 1) }
  const prevStep = () => { setStep(prev => prev - 1) }

  return {
    step,
    nextStep,
    prevStep,
  }
}

export default useSteps
